




























































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import InventoryEditItem from "../components/InventoryEditItem.vue";
import InventoryDeleteItem from "../components/InventoryDeleteItem.vue";
import WorkRequestNew from "../components/WorkRequestNew.vue";
import axios from "axios";
import config from "../config";

@Component({
  components: {
    InventoryEditItem,
    InventoryDeleteItem,
    WorkRequestNew,
  },
})
export default class InventorySearchFilter extends Vue {
  perPage = 10;
  currentPage = 1;
  inventory = null;
  sortBy = 'ControlNo';
  sortDesc = false;
  searchType = 'controlNo';
  searchValue: any = '';

  @Prop({default: null}) list !: any;
  @Prop({default: null}) fields !: any;
  @Prop({default: null}) rows !: any;
  @Prop({default: null}) fetchInventoryList !: any;
  @Prop({default: null}) manufacturers!: any;
  @Prop({default: null}) nomenclatures!: any;
  @Prop({default: null}) models!: any;
  @Prop({default: null}) filters!: any;
  @Prop({ default: null }) clients!: any;
  @Prop({ default: null }) inventoryList!: any;
  @Prop({ default: null }) technicians!: any;

  createWO(inventory) {
    this.inventory = inventory;

    setTimeout(
      () => {
        this.$root.$emit("bv::show::modal", "ModalWorkRequestNew");
      },
      250
    )
  }

  resetSearchValue() {
    this.searchValue = undefined;
    this.filters.where[this.searchType] = this.searchValue;
    this.$emit('fetchInventoryList');
  }

  search() {
    this.$emit('fetchInventoryList');
  }

  @Watch('searchType')
  onSearchTypeChange() {
    this.filters.where.controlNo = undefined;
    this.filters.where.qrserialNo = undefined;
    this.filters.where.nomenclature = undefined;
    this.filters.where.model = undefined;
    this.filters.where.manufacturer = undefined;

    this.filters.where[this.searchType] = this.searchValue;
  }

  @Watch('searchValue')
  onSearchValueChange() {
    if (this.searchValue === '') this.searchValue = undefined;
    this.filters.where[this.searchType] = this.searchValue;
  }

  @Watch('filters.where.manufacturer')
  onManufactureChange() {
    this.$emit('fetchInventoryList');
  }

  @Watch('filters.where.nomenclature')
  onNomenclatureChange() {
    this.$emit('fetchInventoryList');
  }

  @Watch('filters.where.model')
  onModelChange() {
    this.$emit('fetchInventoryList');
  }

  created() {
    this.fetchSerialNumbers();
    this.fetchControlNumbers();
  }

  serialNumbers: any[] = [];

  public async fetchSerialNumbers(): Promise<void> {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (!response.data.success) return;

    const inventory = response.data.data.inventory;

    inventory.forEach((item: any) => {
      this.serialNumbers.push(item.qrserialNo);
    });

    // console.log(this.serialNumbers);
  }

  controlNumbers: any[] = [];

  public async fetchControlNumbers(): Promise<void> {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const inventory = response.data.data.inventory;

    inventory.forEach((item: any) => {
      this.controlNumbers.push(item.controlNo);
    });

    // console.log(this.controlNumbers);
  }

}
