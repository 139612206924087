































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import OrganizationUsersEditItem from "../components/OrganizationUsersEditItem.vue";
import OrganizationUsersDeleteItem from "../components/OrganizationUsersDeleteItem.vue";
import OrganizationUsersRestoreItem from "../components/OrganizationUsersRestoreItem.vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { userService } from '../services';

library.add(faTrashRestore)

@Component({
  components: {
    OrganizationUsersEditItem,
    OrganizationUsersDeleteItem,
    OrganizationUsersRestoreItem,
  },
})
export default class OrganizationSearchFilter extends Vue {
  perPage = 10;
  currentPage = 1;

  @Prop({required: true}) userRole!: string;
  @Prop({default: null}) user!: any;
  @Prop({required: true}) viewOnly!: boolean;

  @Prop({default: null}) list !: any;
  @Prop({default: null}) fields !: any;
  @Prop({default: null}) rows !: any;
  @Prop({default: null}) fetchOrganizationUsersList !: any;
  @Prop({default: null}) filters!: any;

  resetKeyword() {
    this.filters.search_keyword = "";
    this.$emit('fetchOrganizationUsersList');
  }

  search() {
    this.$emit('fetchOrganizationUsersList');
  }

  @Watch('filters.where.role')
  onStatusChange() {
    this.$emit('fetchOrganizationUsersList');
  }

  async impersonate(userId) {
    await userService.impersonate(userId);
    window.location.reload();
  }
}
