










import Verification from "../components/Verification.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { Verification },
})
export default class Verify extends Vue {}
