

























// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BIconFileEarmarkMinusFill } from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import config from "../config";
import router from '../router';

@Component({
  components: {},
})
export default class ConfirmEmail extends Vue {
  @Prop({default: ''}) verificationCode;
  @Prop({default: false}) verifying;
  @Prop({default: false}) submitted;
  @Prop({default: null}) errorMessage;

  get loggedIn() {
    return this.$store.state.authentication.status.loggedIn;
  }

  get isSubmitted() {
    return this.submitted;
  }

  async mounted() {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "users/send-email-verification-code",
      data: {},
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });
  }

  async handleSubmit (e) {
    this.submitted = true;
    this.verifying = true;
    if (this.verificationCode) {
      let response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + "users/confirm-email-verification-code",
        data: {
          verificationCode: this.verificationCode
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });

      response = response.data;

      if (response.success) {
        router.push('/client/request/list');
      }
      else {
        this.errorMessage = 'Invalid Verification Code!';
      }
    }

    this.verifying = false;
  }
}
