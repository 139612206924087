







































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VueCsvImport from "vue-csv-import";
import axios from "axios";
import config from "../config";

@Component({ components: { VueCsvImport } })
export default class InventoryUploadCSV extends Vue {
  public file: any;

  @Prop({ default: null }) clients!: any;

  isImporting = false;

  csv: any = null;
  rows: any = 0;
  imported: any = 0;
  @Watch('csv')
  csvChanged(newVal: string) {
    this.rows = newVal.length;
  }

  fields: string[] = [
    "ControlNo",
    "Manufacturer",
    "Nomenclature",
    "Model",
    "SerialNo",
  ];
  selectedClient: any = null;

  @Watch('inv.clientId')
  onClient(clientId) {
    for (const i in this.clients) {
      const client = this.clients[i];
      if (client.clientId === clientId) {
        this.selectedClient = client;
        break;
      }
    }
  }

  isCreateFailed = false;

  public async importData(): Promise<void> {
    if (!this.selectedClient) {
      this.isCreateFailed = true;
      return;
    }

    this.isImporting = true;
    const inventory = this.csv;

    for (const i in inventory) {
      const item = {
        controlNo: inventory[i].ControlNo,
        manufacturer: inventory[i].Manufacturer,
        nomenclature: inventory[i].Nomenclature,
        model: inventory[i].Model,
        qrserialNo: inventory[i].SerialNo,
        clientId: this.selectedClient.clientId,
      };

      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + "inventory/create",
        data: item,
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });

      const success = response.data.success;

      if (success) {
        this.imported += 1;
      }
    }

    this.$bvModal.hide('ModalUploadCSV');
    this.$emit('fetchInventoryList');
    this.isImporting = false;
  }

  created() {
    this.$root.$on('bv::modal::show', () => {
      if (this.clients && this.clients.length && !this.selectedClient) {
        this.selectedClient = this.clients[0];
      }
    })
  }
}
