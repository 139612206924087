import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faFilter,
  faEdit,
  faTrash,
  faQrcode,
  faUser,
  faPhone,
  faEnvelope,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faSearch,
  faTimes,
  faFilter,
  faEdit,
  faTrash,
  faQrcode,
  faUser,
  faPhone,
  faEnvelope,
  faExchangeAlt
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
