import config from '../config';
import { authentication } from '../store/authentication.module';

export const userService = {
  login,
  register,
  logout,
  verifyUser,
  impersonate,
};

function verifyUser() {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + authentication.state.token,
      'x-access-token': authentication.state.token
    },
  };

  return fetch(`${config.apiEndpoint}/users/get-user`, requestOptions)
    .then(async (response: any) => {
      response = await response.json();
      // login successful if there's a jwt token in the response
      if (response.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(response.data.user));

        return response.data;
      }

      throw new Error(response.msg);
    });
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: email, password })
  };

  return fetch(`${config.apiEndpoint}/users/login`, requestOptions)
    .then(async (response: any) => {
      response = await response.json();
      // login successful if there's a jwt token in the response
      if (response.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('token', response.data.accessToken);

        return response.data;
      }

      throw new Error(response.msg);
    });
}

function register(email, password, firstName, lastName, phoneNumber, phoneNumberMetadata) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, firstName, lastName, phoneNumber, phoneNumberMetadata })
  };

  return fetch(`${config.apiEndpoint}/users/register`, requestOptions)
    .then(async (response: any) => {
      response = await response.json();
      // login successful if there's a jwt token in the response
      if (response.success) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('token', response.data.accessToken);

        return response.data;
      }

      throw new Error(response.msg);
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}

function impersonate(userId) {
  const requestOptions = {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + authentication.state.token,
      'x-access-token': authentication.state.token
    },
  };

  return fetch(`${config.apiEndpoint}/users/impersonate/${userId}`, requestOptions)
    .then(async (response: any) => {
      response = await response.json();

      if (response.success) {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        localStorage.setItem('token', response.data.accessToken);

        return response.data;
      }

      throw new Error(response.msg);
    });
}
