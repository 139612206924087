


































import { Component, Vue } from "vue-property-decorator";
@Component
export default class Registration extends Vue {
  resetNewPassword = "";
  resetRepeatNewPassword = "";
}
