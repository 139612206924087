































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import WorkRequestEdit from "../components/WorkRequestEdit.vue";
import WorkRequestDeleteItem from "../components/WorkRequestDeleteItem.vue";

@Component({
  components: {
    WorkRequestEdit,
    WorkRequestDeleteItem,
  },
})
export default class WorkRequestSearchFilter extends Vue {
  perPage = 10;
  currentPage = 1;
  sortBy = 'WorkNo';
  sortDesc = true;
  searchType = 'controlNo';
  searchValue: any = '';

  @Prop({default: null}) list !: any;
  @Prop({default: null}) fields !: any;
  @Prop({default: 0}) rows !: any;
  @Prop({default: null}) fetchWorkRequests !: any;
  @Prop({default: null}) manufacturers!: any;
  @Prop({default: null}) nomenclatures!: any;
  @Prop({default: null}) models!: any;
  @Prop({default: null}) serialNumbers!: any;
  @Prop({default: null}) controlNumbers!: any;
  @Prop({default: null}) filters!: any;

  @Prop({ default: null }) inventoryList!: any;

  @Prop({ default: null }) clients!: any;
  @Prop({ default: null }) technicians!: any;

  @Prop({default: false}) isLoading !: any;

  resetSearchValue() {
    this.searchValue = undefined;
    this.filters.where[this.searchType] = this.searchValue;
    this.$emit('fetchWorkRequests');
  }

  search() {
    this.$emit('fetchWorkRequests');
  }

  @Watch('searchType')
  onSearchTypeChange() {
    this.filters.where.controlNo = undefined;
    this.filters.where.qrserialNo = undefined;
    this.filters.where.nomenclature = undefined;
    this.filters.where.model = undefined;
    this.filters.where.manufacturer = undefined;

    this.filters.where[this.searchType] = this.searchValue;
  }

  @Watch('searchValue')
  onSearchValueChange() {
    if (this.searchValue === '') this.searchValue = undefined;
    this.filters.where[this.searchType] = this.searchValue;
  }

  @Watch('filters.where.status')
  onStatusChange() {
    this.$emit('fetchWorkRequests');
  }

  @Watch('filters.order')
  onOrderChange() {
    this.$emit('fetchWorkRequests');
  }
}
