




































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Qrpage from "../components/Qrpage.vue";
import axios from "axios";
import config from "../config";
import vSelect from "vue-select";

@Component({
  components: {
    Qrpage,
    vSelect,
  },
})
export default class InventoryItem extends Vue {
  @Prop({ default: null }) manufacturers!: any;
  @Prop({ default: null }) nomenclatures!: any;
  @Prop({ default: null }) models!: any;
  @Prop({ default: null }) inventoryList!: any;
  @Prop({ default: null }) clients!: any;

  decodedString!: string;
  inv: {
    manufacturer: string;
    nomenclature: string;
    model: string;
    qrserialNo: string;
    controlNo: string;
    manualManufacturer: boolean;
    manualNomenclature: boolean;
    manualModel: boolean;
    clientId: string;
  } = {
    manufacturer: "",
    nomenclature: "",
    model: "",
    qrserialNo: "",
    controlNo: "",
    manualManufacturer: false,
    manualNomenclature: false,
    manualModel: false,
    clientId: "",
  };

  $isMobile!:any;
  isQrEnabled = this.$isMobile();
  isCreateFailed = false;
  errorMessage = false;
  manualManufacturer = false;
  manualNomenclature = false;
  manualModel = false;
  selectedClient: any = null;

  @Watch('inv.clientId')
  onClient(clientId) {
    for (const i in this.clients) {
      const client = this.clients[i];
      if (client.clientId === clientId) {
        this.selectedClient = client;
        break;
      }
    }
  }

  public onDecode(decodedString: string): void {
    // console.log(decodedString);
    this.inv.qrserialNo = decodedString;
  }

  @Watch('inv.manufacturer')
  onInventoryManufacturer(manufacturer) {
    this.nomenclatures = [];
    if (!this.manualNomenclature) this.inv.nomenclature = '';
    if (!this.manualModel) this.inv.model = '';

    this.inventoryList.forEach((item: any) => {
      if (manufacturer !== item.Manufacturer) return;

      if (!this.nomenclatures.includes(item.Nomenclature)) this.nomenclatures.push(item.Nomenclature);
    });
  }

  @Watch('inv.nomenclature')
  onInventoryModel(nomenclature) {
    this.models = [];
    if (!this.manualModel) this.inv.model = '';

    this.inventoryList.forEach((item: any) => {
      if (nomenclature !== item.Nomenclature) return;

      if (!this.models.includes(item.Model)) this.models.push(item.Model);
    });
  }

  public async create(): Promise<void> {
    this.isCreateFailed = false;

    if (!this.inv.clientId || !this.selectedClient) {
      this.isCreateFailed = true;
      return;
    }

    const invAdd = {
      manufacturer: this.inv.manufacturer,
      nomenclature: this.inv.nomenclature,
      model: this.inv.model,
      qrserialNo: this.inv.qrserialNo,
      controlNo: this.inv.controlNo,
      manualManufacturer: this.manualManufacturer,
      manualNomenclature: this.manualNomenclature,
      manualModel: this.manualModel,
      clientId: this.inv.clientId,
    };

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/create",
      data: invAdd,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      this.inv = {
        manufacturer: "",
        nomenclature: "",
        model: "",
        qrserialNo: "",
        controlNo: "",
        manualManufacturer: false,
        manualNomenclature: false,
        manualModel: false,
        clientId: "",
      };

      this.manualManufacturer = false;
      this.manualNomenclature = false;
      this.manualModel = false;

      // console.log(response.data);
      this.$bvModal.hide('ModalAddInventoryItem');
      this.$emit('fetchInventoryList');
    }
    else {
      this.isCreateFailed = true;
      this.errorMessage = response.data.msg;
    }
  }

  public async saveAndCreateNew(): Promise<void> {
    this.isCreateFailed = false;

    if (!this.inv.clientId || !this.selectedClient) {
      this.isCreateFailed = true;
      return;
    }

    const invAdd = {
      manufacturer: this.inv.manufacturer,
      nomenclature: this.inv.nomenclature,
      model: this.inv.model,
      qrserialNo: this.inv.qrserialNo,
      controlNo: this.inv.controlNo,
      manualManufacturer: this.manualManufacturer,
      manualNomenclature: this.manualNomenclature,
      manualModel: this.manualModel,
      clientId: this.inv.clientId,
    };
    // console.log(invAdd);

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/create",
      data: invAdd,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      this.inv = {
        manufacturer: "",
        nomenclature: "",
        model: "",
        qrserialNo: "",
        controlNo: "",
        manualManufacturer: false,
        manualNomenclature: false,
        manualModel: false,
        clientId: "",
      };

      this.manualManufacturer = false;
      this.manualNomenclature = false;
      this.manualModel = false;

      // console.log(response.data);
      this.$bvModal.hide('ModalAddInventoryItem');
      this.$emit('fetchInventoryList');

      setTimeout(() => {
        this.$bvModal.show('ModalAddInventoryItem');
      }, 500);
    }
    else {
      this.isCreateFailed = true;
      this.errorMessage = response.data.msg;
    }
  }

  created() {
    this.$root.$on('bv::modal::show', () => {
      if (this.clients && this.clients.length && !this.selectedClient) {
        this.inv.clientId = this.clients[0].clientId;
        this.selectedClient = this.clients[0];
      }
    })
  }
}
