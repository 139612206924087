











import ResetPassword from "../components/ResetPassword.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ResetPassword },
})
export default class Reset extends Vue {
  new_password = "";
  repeat_new_password = "";
}
