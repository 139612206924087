























import { Component, Vue } from "vue-property-decorator";
@Component
export default class ForgotPassword extends Vue {
  email = "";
}
