





























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

@Component({
  components: {
    VuePhoneNumberInput,
  },
})
export default class Registration extends Vue {
  @Prop({default: ''}) email;
  @Prop({default: ''}) firstName;
  @Prop({default: ''}) lastName;
  @Prop({default: ''}) phoneNumber;
  @Prop({default: ''}) password;
  @Prop({default: ''}) repeatPassword;
  @Prop({default: false}) submitted;

  formData: {
    phoneNumberMetadata: string;
  } = {
    phoneNumberMetadata: "",
  };

  inputPhoneNumber = "";

  error: {
    phoneNumber: boolean;
  } = {
    phoneNumber: false,
  };

  onChangePhoneNumber = (val) => {
    this.error.phoneNumber = !val.isValid;
    if (!val.isValid) return;
    this.formData.phoneNumberMetadata = val;
    this.phoneNumber = val.formatInternational;
  }

  get registeringIn() {
    return this.$store.state.authentication.status.registeringIn;
  }

  get loggedIn() {
    return this.$store.state.authentication.status.loggedIn;
  }

  get errorMessage() {
    return this.$store.state.authentication.status.errorMessage;
  }

  get isSubmitted() {
    return this.submitted;
  }

  handleSubmit (e) {
    setTimeout(() => { this.submitted = true; }, 1000);
    const { dispatch } = this.$store;
    if (
      this.email &&
      this.password &&
      this.repeatPassword &&
      (this.password === this.repeatPassword) &&
      this.firstName &&
      this.lastName &&
      this.phoneNumber &&
      !this.error.phoneNumber
    ) {
      dispatch('authentication/register', {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        phoneNumberMetadata: this.formData.phoneNumberMetadata,
      });
    }
  }
}
