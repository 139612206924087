





















































import InventorySearchFilter from "../components/InventorySearchFilter.vue";
import InventoryItem from "../components/InventoryItem.vue";
import InventoryEditItem from "../components/InventoryEditItem.vue";
import InventoryUploadCSV from "../components/InventoryUploadCSV.vue";
import InventoryDeleteItem from "../components/InventoryDeleteItem.vue";
import InventoryCannotDeleteItem from "../components/InventoryCannotDeleteItem.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import config from "../config";

@Component({
  components: {
    InventorySearchFilter,
    InventoryItem,
    InventoryEditItem,
    InventoryUploadCSV,
    InventoryDeleteItem,
    InventoryCannotDeleteItem,
  },
})
export default class ClientInventoryList extends Vue {
  fields: any[] = [
    { key: 'ControlNo', sortable: true },
    { key: 'Manufacturer', sortable: true },
    { key: 'Nomenclature', sortable: true },
    { key: 'Model', sortable: true },
    { key: 'SerialNo', sortable: true },
    { key: 'Actions', sortable: false },
  ];

  list: Array<{
    inventoryId: string;
    ID: number;
    ControlNo: string;
    Manufacturer: string;
    Nomenclature: string;
    Model: string;
    SerialNo: string;
    clientId: string;
  }> = [];
  rows = this.list.length;

  filters: any = {
    count: 0,
    page: 1,
    limit: 10,
    skip: 0,
    search_keyword: undefined,
    where: {
      controlNo: undefined,
      qrserialNo: undefined,
      nomenclature: undefined,
      model: undefined,
      manufacturer: undefined,
    },
  }

  created() {
    this.fetchInventoryList();
    this.getClients();
    this.getTechnicians();
  }

  manufacturers: any = [];
  nomenclatures: any = [];
  models: any = [];

  organization: any = null;

  inventoryList = [];

  public async fetchInventoryList(): Promise<void> {
    this.filters.skip = (this.filters.page - 1) * this.filters.limit;

    this.filters.skip = 0;
    this.filters.limit = 0;

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: this.filters,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (!response.data.success) return;

    this.filters.count = response.data.data.inventory_count;

    const inventory = response.data.data.inventory;

    const _inventory: any = [];

    inventory.forEach((item: any) => {
      _inventory.push({
        inventoryId: item.inventoryId,
        ID: item.numberId,
        ControlNo: item.controlNo,
        Manufacturer: item.manufacturer,
        Nomenclature: item.nomenclature,
        Model: item.model,
        SerialNo: item.qrserialNo,
        manualManufacturer: item.manualManufacturer,
        manualNomenclature: item.manualNomenclature,
        manualModel: item.manualModel,
        clientId: item.clientId,
      });

      if (!this.manufacturers.includes(item.manufacturer)) this.manufacturers.push(item.manufacturer);
    });

    this.list = _inventory;

    this.inventoryList = inventory;
  }

  clients: any = null;

  async getClients() {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "clients/search",
      data: {},
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (response.data.success) {
      this.clients = response.data.data.clients;
    }
  }

  technicians = [];

  async getTechnicians() {
    const response: any = await axios({
      method: 'get',
      url: config.apiEndpoint + "users/fetchTechnicians",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (response.data.success) {
      this.technicians = response.data.data.technicians;
    }
  }

}
