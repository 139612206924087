

















































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import config from "../config";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Multiselect from 'vue-multiselect'

@Component({
  components: {
    VuePhoneNumberInput,
    Multiselect,
  },
})
export default class AccountEditField extends Vue {
  formData: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    phoneNumberMetadata: string;
    password: string;
    repeatPassword: string;
    oldPassword: string;
    additionalRoles: Array<string>;
  } = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    phoneNumberMetadata: "",
    password: "",
    repeatPassword: "",
    oldPassword: "",
    additionalRoles: [],
  };

  inputPhoneNumber = "";

  error: {
    phoneNumber: boolean;
  } = {
    phoneNumber: false,
  };

  options = ['technician'];

  onChangePhoneNumber = (val) => {
    this.error.phoneNumber = !val.isValid;
    if (!val.isValid) return;
    this.formData.phoneNumberMetadata = val;
    this.formData.phoneNumber = val.formatInternational;
  }

  isOwner: any = null;

  mounted() {
    const user = this.$store.state.authentication.user;

    this.formData.email = user.email;
    this.formData.firstName = user.firstName;
    this.formData.lastName = user.lastName;
    this.formData.phoneNumber = user.phoneNumber;
    this.formData.phoneNumberMetadata = user.phoneNumberMetadata;
    this.formData.password = user.password;
    this.formData.repeatPassword = user.repeatPassword;
    this.formData.oldPassword = user.oldPassword;

    this.inputPhoneNumber = this.formData.phoneNumber;

    setTimeout(() => {
      const user = this.$store.state.authentication.user;
      this.isOwner = user.role === 'organization owner';
      for (const i in user.roles) {
        if (this.options.includes(user.roles[i])) {
          this.formData.additionalRoles.push(user.roles[i]);
        }
      }
    }, 1000);
  }

  isUpdateFailed = false;
  isSubmitted = false;
  errorMessage: any = false;
  isUpdateSuccess = false;

  async save(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    this.isUpdateSuccess = false;

    if (
      this.formData.email &&
      (!this.formData.password || this.formData.password === this.formData.repeatPassword) &&
      this.formData.firstName &&
      this.formData.lastName &&
      this.formData.phoneNumber &&
      !this.error.phoneNumber
    ) {
      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + "users/update",
        data: this.formData,
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.$store.dispatch('authentication/verifyUser');
        this.isUpdateSuccess = true;
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }
}
