




















































import { Component, Vue } from "vue-property-decorator";
import router from '../router';

@Component({
  components: {},
})
export default class Navbar extends Vue {
  get loggedIn() {
    return this.$store.state.authentication.status.loggedIn;
  }

  logout() {
    const { dispatch } = this.$store;

    dispatch('authentication/logout');

    router.push('/login');
  }
}
