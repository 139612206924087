import usStatesCities from '../assets/usStatesCities.json';

export const usStatesCitiesService = {
  getAllStates,
  getCities,
};

function getAllStates() {
  const states: any = [];

  for(const state in usStatesCities) {
    states.push(state);
  }

  return states;
}

function getCities(state) {
  return usStatesCities[state];
}
