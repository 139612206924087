












































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Qrpage from "../components/Qrpage.vue";
import axios from "axios";
import config from "../config";
import vSelect from "vue-select";

@Component({
  components: {
    Qrpage,
    vSelect,
  },
})
export default class WorkRequestEdit extends Vue {
  @Prop({default: null}) item !: any;
  @Prop({default: null}) serialNumbers!: any;
  @Prop({default: null}) controlNumbers!: any;
  @Prop({default: null}) manufacturers!: any;
  @Prop({default: null}) nomenclatures!: any;
  @Prop({default: null}) models!: any;
  @Prop({default: null}) control_no !: string;
  @Prop({default: null}) manufacturer !: string;
  @Prop({default: null}) nomenclature !: string;
  @Prop({default: null}) model !: string;
  @Prop({default: null}) serial_no !: string;

  @Prop({default: null}) hasAllPassed !: boolean;
  @Prop({default: null}) hasPowerUpTest !: boolean;
  @Prop({default: null}) hasPressure !: boolean;
  @Prop({default: null}) hasUnitMount !: boolean;
  @Prop({default: null}) hasTightness !: boolean;
  @Prop({default: null}) hasInternalHose !: boolean;
  @Prop({default: null}) hasPowerCord !: boolean;
  @Prop({default: null}) hasGroundResistance !: boolean;
  @Prop({default: null}) hasLeakageCurrent !: boolean;
  @Prop({default: null}) groundResistance !: string;
  @Prop({default: null}) pressureDisplay !: string;
  @Prop({default: null}) symptoms !: string;
  @Prop({default: null}) technician !: string;
  @Prop({default: null}) scheduledForDate !: string;
  @Prop({default: null}) scheduledForTime !: string;
  @Prop({default: null}) status !: string;
  @Prop({default: null}) type !: string;
  @Prop({default: null}) verified !: boolean;
  @Prop({default: null}) groundResistanceNotes !: string;
  @Prop({default: null}) cleanUnitNotes !: string;
  @Prop({default: null}) leakageCurrentNotes !: string;
  @Prop({default: null}) hasCleanUnit !: boolean;
  @Prop({default: null}) leakageCurrentOff !: string;
  @Prop({default: null}) leakageCurrentOn !: string;

  @Prop({default: false}) manualControl !: boolean;
  @Prop({default: false}) manualQrserialNo !: boolean;
  @Prop({default: false}) manualManufacturer !: boolean;
  @Prop({default: false}) manualNomenclature !: boolean;
  @Prop({default: false}) manualModel !: boolean;

  @Prop({default: false}) clientId !: string;

  @Prop({ default: null }) clients!: any;
  @Prop({ default: null }) technicians!: any;

  @Prop({default: false}) hasAttentionLamp !: boolean;
  @Prop({default: false}) hasPusherBlockMsg !: boolean;
  @Prop({default: false}) hasCorrectSyringe !: boolean;
  @Prop({default: false}) hasOcclusion !: boolean;
  @Prop({default: false}) has5mlAlarm !: boolean;
  @Prop({default: false}) hasEmptySyringe !: boolean;
  @Prop({default: false}) hasFlowRate !: boolean;
  @Prop({default: false}) hasGroundingPadCircuit !: boolean;
  @Prop({default: false}) hasTestOutputs !: boolean;
  @Prop({default: []}) testOutputs !: any;
  @Prop({default: false}) hasPittingPaddles !: boolean;
  @Prop({default: false}) hasUnitNotDischarge !: boolean;
  @Prop({default: false}) hasPrinter !: boolean;
  @Prop({default: false}) hasCheckOutputs !: boolean;
  @Prop({default: null}) checkOutputSetting5 !: string;
  @Prop({default: null}) checkOutputSetting50 !: string;
  @Prop({default: null}) checkOutputSetting100 !: string;
  @Prop({default: null}) checkOutputSetting200 !: string;
  @Prop({default: null}) checkOutputSettingMax !: string;

  @Prop({default: 'default'}) template !: string;

  controlOrSerial = (() => {
    if (this.control_no) return 'control';

    return 'serial';
  })();

  decodedString!: string;

  workRequest: {
    manufacturer: string;
    nomenclature: string;
    model: string;
    qrserialNo: string;
    controlNo: string;
    hasAllPassed: boolean;
    hasPowerUpTest: boolean;
    hasPressure: boolean;
    hasUnitMount: boolean;
    hasTightness: boolean;
    hasInternalHose: boolean;
    hasPowerCord: boolean;
    hasGroundResistance: boolean;
    hasLeakageCurrent: boolean;
    verified: boolean;
    groundResistance: string;
    pressureDisplay: string;
    symptoms: string;
    technician: string;
    scheduledForDate: string;
    scheduledForTime: string;
    type: string;
    groundResistanceNotes: string;
    cleanUnitNotes: string;
    leakageCurrentNotes: string;
    hasCleanUnit: boolean;
    leakageCurrentOff: string;
    leakageCurrentOn: string;
    clientId: string;
    hasAttentionLamp: boolean,
    hasPusherBlockMsg: boolean,
    hasCorrectSyringe: boolean,
    hasOcclusion: boolean,
    has5mlAlarm: boolean,
    hasEmptySyringe: boolean,
    hasFlowRate: boolean,
    hasGroundingPadCircuit: boolean,
    hasTestOutputs: boolean,
    testOutputs: any,
    hasPittingPaddles: boolean,
    hasUnitNotDischarge: boolean,
    hasPrinter: boolean,
    hasCheckOutputs: boolean,
    checkOutputSetting5: string,
    checkOutputSetting50: string,
    checkOutputSetting100: string,
    checkOutputSetting200: string,
    checkOutputSettingMax: string,
  } = {
    controlNo: this.control_no,
    manufacturer: this.manufacturer,
    nomenclature: this.nomenclature,
    model: this.model,
    qrserialNo: this.serial_no,
    hasAllPassed: this.hasAllPassed,
    hasPowerUpTest: this.hasPowerUpTest,
    hasPressure: this.hasPressure,
    hasUnitMount: this.hasUnitMount,
    hasTightness: this.hasTightness,
    hasInternalHose: this.hasInternalHose,
    hasPowerCord: this.hasPowerCord,
    hasGroundResistance: this.hasGroundResistance,
    hasLeakageCurrent: this.hasLeakageCurrent,
    verified: this.verified,
    groundResistance: this.groundResistance,
    pressureDisplay: this.pressureDisplay,
    symptoms: this.symptoms,
    technician: this.technician,
    scheduledForDate: this.scheduledForDate,
    scheduledForTime: this.scheduledForTime,
    type: this.type,
    groundResistanceNotes: this.groundResistanceNotes,
    cleanUnitNotes: this.cleanUnitNotes,
    leakageCurrentNotes: this.leakageCurrentNotes,
    hasCleanUnit: this.hasCleanUnit,
    leakageCurrentOff: this.leakageCurrentOff,
    leakageCurrentOn: this.leakageCurrentOn,
    clientId: this.clientId,
    hasAttentionLamp: this.hasAttentionLamp,
    hasPusherBlockMsg: this.hasPusherBlockMsg,
    hasCorrectSyringe: this.hasCorrectSyringe,
    hasOcclusion: this.hasOcclusion,
    has5mlAlarm: this.has5mlAlarm,
    hasEmptySyringe: this.hasEmptySyringe,
    hasFlowRate: this.hasFlowRate,
    hasGroundingPadCircuit: this.hasGroundingPadCircuit,
    hasTestOutputs: this.hasTestOutputs,
    testOutputs: this.testOutputs,
    hasPittingPaddles: this.hasPittingPaddles,
    hasUnitNotDischarge: this.hasUnitNotDischarge,
    hasPrinter: this.hasPrinter,
    hasCheckOutputs: this.hasCheckOutputs,
    checkOutputSetting5: this.checkOutputSetting5,
    checkOutputSetting50: this.checkOutputSetting50,
    checkOutputSetting100: this.checkOutputSetting100,
    checkOutputSetting200: this.checkOutputSetting200,
    checkOutputSettingMax: this.checkOutputSettingMax,
  };

  $isMobile!:any;
  isQrEnabled = this.$isMobile();
  isCreateFailed = false;
  errorMessage = false;
  invalidAmps: any = false;
  invalidOhms: any = false;
  selectedClient: any = null;

  templates = [
    {
      name: 'default',
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
      hasCleanUnit: true,
    },
    {
      name: 'baxterPCA2pump',
      hasPowerUpTestWithSyringe: true,
      hasAttentionLamp: true,
      hasPusherBlockMsg: true,
      hasCorrectSyringe: true,
      hasOcclusion: true,
      has5mlAlarm: true,
      hasEmptySyringe: true,
      hasFlowRate: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
    {
      name: 'generic Defib',
      hasPowerUpTest: true,
      hasUnitNotDischarge: true,
      hasPrinter: true,
      hasCheckOutputs: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
    {
      name: 'generic ESU',
      hasPowerUpTest: true,
      hasGroundingPadCircuit: true,
      hasTestOutputs: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
    {
      name: 'strykersmartpump',
      hasPowerUpTest: true,
      hasPressure: true,
      hasUnitMount: true,
      hasTightness: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
  ];
  selectedTemplate = this.templates[0];

  public onDecode(decodedString: string): void {
    // console.log(decodedString);
    this.item.qrserialNo = decodedString;
  }

  @Prop({ default: null }) inventoryList!: any;

  @Watch('workRequest.clientId')
  onClient(clientId) {
    for (const i in this.clients) {
      const client = this.clients[i];
      if (client.clientId === clientId) {
        this.selectedClient = client;
        break;
      }
    }
  }

  @Watch('workRequest.manufacturer')
  onInventoryManufacturer(manufacturer) {
    this.nomenclatures = [];
    if (!this.manualNomenclature) this.workRequest.nomenclature = '';
    if (!this.manualModel) this.workRequest.model = '';

    this.inventoryList.forEach((item: any) => {
      if (manufacturer !== item.manufacturer) return;

      if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
    });
  }

  @Watch('workRequest.nomenclature')
  onInventoryModel(nomenclature) {
    this.models = [];
    if (!this.manualModel) this.workRequest.model = '';

    this.inventoryList.forEach((item: any) => {
      if (nomenclature !== item.nomenclature) return;

      if (!this.models.includes(item.model)) this.models.push(item.model);
    });
  }

  public async update(): Promise<void> {
    if (!this.workRequest.clientId || !this.selectedClient || this.invalidOhms || this.invalidAmps) {
      this.isCreateFailed=true;
      return;
    }
    const workRequestEdit = {
      manualControl: this.manualControl,
      manualQrserialNo: this.manualQrserialNo,
      manualManufacturer: this.manualManufacturer,
      manualNomenclature: this.manualNomenclature,
      manualModel: this.manualModel,
      manufacturer: this.workRequest.manufacturer,
      nomenclature: this.workRequest.nomenclature,
      model: this.workRequest.model,
      qrserialNo: this.workRequest.qrserialNo,
      controlNo: this.workRequest.controlNo,
      hasAllPassed: this.workRequest.hasAllPassed,
      hasPowerUpTest: this.workRequest.hasPowerUpTest,
      hasPressure: this.workRequest.hasPressure,
      hasUnitMount: this.workRequest.hasUnitMount,
      hasTightness: this.workRequest.hasTightness,
      hasInternalHose: this.workRequest.hasInternalHose,
      hasPowerCord: this.workRequest.hasPowerCord,
      hasGroundResistance: this.workRequest.hasGroundResistance,
      hasLeakageCurrent: this.workRequest.hasLeakageCurrent,
      verified: this.workRequest.verified,
      groundResistance: this.workRequest.groundResistance,
      pressureDisplay: this.workRequest.pressureDisplay,
      symptoms: this.workRequest.symptoms,
      technician: this.workRequest.technician,
      scheduledForDate: this.workRequest.scheduledForDate,
      scheduledForTime: this.workRequest.scheduledForTime,
      type: this.workRequest.type,
      groundResistanceNotes: this.workRequest.groundResistanceNotes,
      cleanUnitNotes: this.workRequest.cleanUnitNotes,
      leakageCurrentNotes: this.workRequest.leakageCurrentNotes,
      hasCleanUnit: this.workRequest.hasCleanUnit,
      leakageCurrentOff: this.workRequest.leakageCurrentOff,
      leakageCurrentOn: this.workRequest.leakageCurrentOn,
      clientId: this.workRequest.clientId,
      template: this.selectedTemplate.name,
      hasAttentionLamp: this.workRequest.hasAttentionLamp,
      hasPusherBlockMsg: this.workRequest.hasPusherBlockMsg,
      hasCorrectSyringe: this.workRequest.hasCorrectSyringe,
      hasOcclusion: this.workRequest.hasOcclusion,
      has5mlAlarm: this.workRequest.has5mlAlarm,
      hasEmptySyringe: this.workRequest.hasEmptySyringe,
      hasFlowRate: this.workRequest.hasFlowRate,
      hasGroundingPadCircuit: this.workRequest.hasGroundingPadCircuit,
      hasTestOutputs: this.workRequest.hasTestOutputs,
      testOutputs: this.testOutputs,
      hasPittingPaddles: this.workRequest.hasPittingPaddles,
      hasUnitNotDischarge: this.workRequest.hasUnitNotDischarge,
      hasPrinter: this.workRequest.hasPrinter,
      hasCheckOutputs: this.workRequest.hasCheckOutputs,
      checkOutputSetting5: this.workRequest.checkOutputSetting5,
      checkOutputSetting50: this.workRequest.checkOutputSetting50,
      checkOutputSetting100: this.workRequest.checkOutputSetting100,
      checkOutputSetting200: this.workRequest.checkOutputSetting200,
      checkOutputSettingMax: this.workRequest.checkOutputSettingMax,
    };
    // console.log(workRequestAdd);
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "WorkRequests/" + this.item.workRequestId,
      data: workRequestEdit,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });
    const success = response.data.success;

    if (success) {
      // console.log(response.data);
      this.$bvModal.hide('ModalWorkRequestEdit-' + this.item.ID);
      this.$emit('fetchWorkRequests');
    }
    else {
      this.isCreateFailed = true;
      // this.errorMessage = response.data.msg;
    }
  }

  @Watch('controlOrSerial')
  async onControlOrSerialChange(val) {
    this.workRequest.controlNo = "";
    this.workRequest.qrserialNo = "";
    this.workRequest.manufacturer = "";
    this.workRequest.nomenclature = "";
    this.workRequest.model = "";
  }

  @Watch('workRequest.controlNo')
  async onControlChange(val) {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: {
        where: {
          controlNo: val,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      const data = response.data.data;

      if (data.inventory[0]) {
        const inventory = data.inventory[0];

        this.workRequest.qrserialNo = inventory.qrserialNo;
        this.workRequest.manufacturer = inventory.manufacturer;

        this.inventoryList.forEach((item: any) => {
          if (inventory.manufacturer !== item.manufacturer) return;

          if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
        });

        this.inventoryList.forEach((item: any) => {
          if (inventory.nomenclature !== item.nomenclature) return;

          if (!this.models.includes(item.model)) this.models.push(item.model);
        });

        setTimeout(() => {
          this.workRequest.nomenclature = inventory.nomenclature;
          this.workRequest.model = inventory.model;
        }, 100);
      }
    }
  }

  created() {
    this.$root.$on('bv::modal::show', () => {
      this.nomenclatures = [];
      this.inventoryList.forEach((item: any) => {
        if (this.workRequest.manufacturer !== item.manufacturer) return;

        if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
      });

      this.models = [];
      this.inventoryList.forEach((item: any) => {
        if (this.workRequest.nomenclature !== item.nomenclature) return;

        if (!this.models.includes(item.model)) this.models.push(item.model);
      });

      for (const i in this.clients) {
        const client = this.clients[i];
        if (client.clientId === this.workRequest.clientId) {
          this.selectedClient = client;
          break;
        }
      }

      for (const i in this.templates) {
        const template = this.templates[i];
        if (template.name === this.template) {
          this.selectedTemplate = template;
          break;
        }
      }

      this.workRequest.testOutputs = this.testOutputs;

      this.testOutputs = [];

      for (let i = 0; i < 5; i++) {
        this.testOutputs.push({
          setting: this.workRequest.testOutputs[i] ? this.workRequest.testOutputs[i].setting : '',
          ablate: this.workRequest.testOutputs[i] ? this.workRequest.testOutputs[i].ablate : '',
          coblate: this.workRequest.testOutputs[i] ? this.workRequest.testOutputs[i].coblate : '',
        });
      }
    })
  }

  @Watch('workRequest.qrserialNo')
  async onSerialChange(val) {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: {
        where: {
          qrserialNo: val,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      const data = response.data.data;

      if (data.inventory[0]) {
        const inventory = data.inventory[0];

        this.workRequest.controlNo = inventory.controlNo;
        this.workRequest.manufacturer = inventory.manufacturer;

        this.inventoryList.forEach((item: any) => {
          if (inventory.manufacturer !== item.manufacturer) return;

          if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
        });

        this.inventoryList.forEach((item: any) => {
          if (inventory.nomenclature !== item.nomenclature) return;

          if (!this.models.includes(item.model)) this.models.push(item.model);
        });

        setTimeout(() => {
          this.workRequest.nomenclature = inventory.nomenclature;
          this.workRequest.model = inventory.model;
        }, 100);
      }
    }
  }

  @Watch('workRequest.groundResistance')
  onGroundResistanceChange(val) {
    if (+val > 1000000) {
      this.invalidOhms = true;
    }
    else {
      this.invalidOhms = false;
    }
  }

  @Watch('workRequest.leakageCurrentOff')
  onLeakageCurrentOffChange(val) {
    if (+val > 1000000) {
      this.invalidAmps = true;
    }
    else {
      this.invalidAmps = false;
    }
  }

  @Watch('workRequest.leakageCurrentOn')
  onLeakageCurrentOnChange(val) {
    if (+val > 1000000) {
      this.invalidAmps = true;
    }
    else {
      this.invalidAmps = false;
    }
  }
}
