



















import { Component, Vue, Prop } from "vue-property-decorator";
import { StreamBarcodeReader } from "vue-barcode-reader";
@Component({
  components: { StreamBarcodeReader },
})
export default class Qrpage extends Vue {
  @Prop() decodedString!: string;

  public isShowingCamera = false;

  public openCamera(): void {
    this.isShowingCamera = !this.isShowingCamera;
  }

  //F

  public onDecode(decodedString: string): void {
    this.$emit("data", decodedString);
    this.isShowingCamera = false;
  }

  public async onInit(promise: Promise<string>): Promise<void> {
    try {
      await promise;
      // console.log("init", promise);
    } catch (error) {
      // console.log(error);
      if (error.name === "NotAllowedError") {
        //denied camera access permission
      } else if (error.name === "NotFoundError") {
        // No suitable camera installed
      } else if (error.name === "NotSupportedError") {
        //Testing
      } else if (error.name === "NotReadableError") {
        //Camera is Already in use
      } else if (error.name === "OverconstrainedError") {
        //No Front camera
      } else if (error.name === "StreamApiNotSupportedError") {
        //Lackng features
      }
    } finally {
      //hide loading indicator
    }
  }
}
