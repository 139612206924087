























































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import config from "../config";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import JsonCSV from 'vue-json-csv';

Vue.component('downloadCsv', JsonCSV);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM DD, YYYY')
  }
});

@Component({
  components: {
    DateRangePicker,
  },
})
export default class WorkRequestDeleteItem extends Vue {
  dateRange = {
    startDate: new Date(),
    endDate: new Date(),
  };

  public async fetchWorkRequests(startDate, endDate): Promise<void> {
    const filters = {
      where: {
        and: [
          {
            scheduledForDate: {
              gt: startDate,
            },
          },
          {
            scheduledForDate: {
              lt: endDate,
            },
          }
        ]
      },
    };

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "WorkRequests/search",
      data: filters,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const WorkRequests = response.data.data.workRequests;

    const _workRequests: any = [];

    WorkRequests.forEach((item: any) => {
      let testOutputs = '';
      if (item.testOutputs) {
         testOutputs = JSON.stringify(item.testOutputs);
         testOutputs = testOutputs.replace(/"/g,'');
         testOutputs = testOutputs.replace(/:/g,'=');
         testOutputs = testOutputs.replace(/},{/g,'; ');
         testOutputs = testOutputs.replace(/\[{/g,'');
         testOutputs = testOutputs.replace(/}\]/g,'');
         testOutputs = testOutputs.replace(/,/g,', ');
      }

      const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      };
      
      //console.log("testOutputs = ", item.testOutputs);
      _workRequests.push({
        // workRequestId: item.workRequestId,
        // ID: pad(item.numberId, 8, '0'),
        WorkNo: pad(item.numberId, 8, '0'),
        // template: item.template,
        ControlNo: item.controlNo || item.manualControl,
        Manufacturer: item.manufacturer || item.manualManufacturer,
        Nomenclature: item.nomenclature || item.manualNomenclature,
        Model: item.model || item.manualModel,
        SerialNo: item.qrserialNo || item.manualQrserialNo,
        //HasAllPassed: item.hasAllPassed,
        //HasPowerUpTest: item.hasPowerUpTest,
        //HasPressure: item.hasPressure,
        //HasUnitMount: item.hasUnitMount,
        //HasTightness: item.hasTightness,
        //HasInternalHose: item.hasInternalHose,
        //HasPowerCord: item.hasPowerCord,
        //HasGroundResistance: item.hasGroundResistance,
        //HasLeakageCurrent: item.hasLeakageCurrent,
        //Verified: item.verified,
        GroundResistance: item.groundResistance,
        //PressureDisplay: item.pressureDisplay,
        Symptoms: item.symptoms,
        Technician: item.technician ? item.technician.fullName : '',
        ScheduledForDate: item.scheduledForDate,
        //ScheduledForTime: item.scheduledForTime,
        Status: item.status,
        Request: item.type,
        Schedule: moment(item.schedule).format("MM-DD-YYYY HH:mm:ss"),
        HasGroundResistanceNotes: item.groundResistanceNotes,
        CleanUnitNotes: item.cleanUnitNotes,
        LeakageCurrentNotes: item.leakageCurrentNotes,
        //HasCleanUnit: item.hasCleanUnit,
        LeakageCurrentOff: item.leakageCurrentOff,
        LeakageCurrentOn: item.leakageCurrentOn,
        // manualControl: item.manualControl,
        // manualQrserialNo: item.manualQrserialNo,
        // manualManufacturer: item.manualManufacturer,
        // manualNomenclature: item.manualNomenclature,
        // manualModel: item.manualModel,
        // clientId: item.clientId,
        //hasAttentionLamp: item.hasAttentionLamp,
        //hasPusherBlockMsg: item.hasPusherBlockMsg,
        //hasCorrectSyringe: item.hasCorrectSyringe,
        //hasOcclusion: item.hasOcclusion,
        //has5mlAlarm: item.has5mlAlarm,
        //hasEmptySyringe: item.hasEmptySyringe,
        //hasFlowRate: item.hasFlowRate,
        //hasGroundingPadCircuit: item.hasGroundingPadCircuit,
        //hasTestOutputs: item.hasTestOutputs,
        
        TestOutputsData: testOutputs,
        
        //hasPittingPaddles: item.hasPittingPaddles,
        //hasUnitNotDischarge: item.hasUnitNotDischarge,
        //hasPrinter: item.hasPrinter,
        //hasCheckOutputs: item.hasCheckOutputs,
        CheckOutputSetting5: item.checkOutputSetting5,
        CheckOutputSetting50: item.checkOutputSetting50,
        CheckOutputSetting100: item.checkOutputSetting100,
        CheckOutputSetting200: item.checkOutputSetting200,
        CheckOutputSettingMax: item.checkOutputSettingMax,
      });
    });

    return _workRequests
  }

  workRequests: any = [];
  filename = 'filename';

  public async updateRange(): Promise<void> {
    const workRequests = await this.fetchWorkRequests(this.dateRange.startDate, this.dateRange.endDate);
    this.workRequests = workRequests;
  }

  created() {
    this.$root.$on('bv::modal::show', () => {
      this.updateRange();
    });
  }
}
