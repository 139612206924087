














































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Qrpage from "../components/Qrpage.vue";
import axios from "axios";
import config from "../config";
import vSelect from "vue-select";

@Component({components: {
    Qrpage,
    vSelect,
}})
export default class InventoryEditItem extends Vue {
  @Prop({default: null}) item !: any;
  @Prop({default: null}) manufacturers!: any;
  @Prop({default: null}) nomenclatures!: any;
  @Prop({default: null}) models!: any;
  @Prop({default: null}) control_no !: string;
  @Prop({default: null}) manufacturer !: string;
  @Prop({default: null}) nomenclature !: string;
  @Prop({default: null}) model !: string;
  @Prop({default: null}) serial_no !: string;

  @Prop({default: false}) manualManufacturer !: boolean;
  @Prop({default: false}) manualNomenclature !: boolean;
  @Prop({default: false}) manualModel !: boolean;

  @Prop({default: false}) clientId !: string;

  @Prop({ default: null }) inventoryList!: any;
  @Prop({ default: null }) clients!: any;

  inv: {
    controlNo: string;
    manufacturer: string;
    nomenclature: string;
    model: string;
    qrserialNo: string;
    clientId: string;
  } = {
    controlNo: this.control_no,
    manufacturer: this.manufacturer,
    nomenclature: this.nomenclature,
    model: this.model,
    qrserialNo: this.serial_no,
    clientId: this.clientId,
  };

  selectedClient: any = null;

  @Watch('inv.clientId')
  onClient(clientId) {
    for (const i in this.clients) {
      const client = this.clients[i];
      if (client.clientId === clientId) {
        this.selectedClient = client;
        break;
      }
    }
  }

  @Watch('item.controlNo')
  onControlChange(val: any) {
    this.inv.controlNo = val;
  }

  @Watch('item.manufacturer')
  onManufacturerChange(val: any) {
    this.inv.manufacturer = val;
  }

  @Watch('item.nomenclature')
  onNomenclatureChange(val: any) {
    this.inv.nomenclature = val;
  }

  @Watch('item.model')
  onModelChange(val: any) {
    this.inv.model = val;
  }

  @Watch('item.qrserialNo')
  onQRChange(val: any) {
    this.inv.qrserialNo = val;
  }

  created() {
    this.$root.$on('bv::modal::show', () => {
      this.inventoryList.forEach((item: any) => {
        if (this.inv.manufacturer !== item.Manufacturer) return;

        if (!this.nomenclatures.includes(item.Nomenclature)) this.nomenclatures.push(item.Nomenclature);
      });

      this.inventoryList.forEach((item: any) => {
        if (this.inv.nomenclature !== item.Nomenclature) return;

        if (!this.models.includes(item.Model)) this.models.push(item.Model);
      });

      for (const i in this.clients) {
        const client = this.clients[i];
        if (client.clientId === this.inv.clientId) {
          this.selectedClient = client;
          break;
        }
      }
    })
  }

  @Watch('inv.manufacturer')
  onInventoryManufacturer(manufacturer) {
    this.nomenclatures = [];
    if (!this.manualNomenclature) this.inv.nomenclature = '';
    if (!this.manualModel) this.inv.model = '';

    this.inventoryList.forEach((item: any) => {
      if (manufacturer !== item.Manufacturer) return;

      if (!this.nomenclatures.includes(item.Nomenclature)) this.nomenclatures.push(item.Nomenclature);
    });

    console.log(this.nomenclatures);
  }

  @Watch('inv.nomenclature')
  onInventoryModel(nomenclature) {
    this.models = [];
    if (!this.manualModel) this.inv.model = '';

    this.inventoryList.forEach((item: any) => {
      if (nomenclature !== item.Nomenclature) return;

      if (!this.models.includes(item.Model)) this.models.push(item.Model);
    });
  }

  decodedString !: string;

  $isMobile!:any;

  isQrEnabled = this.$isMobile();
  isUpdateFailed = false;
  errorMessage = false;

  public onDecode(decodedString : string) : void {
    this.inv.qrserialNo = decodedString;
  }

  public async save(): Promise<void> {
    this.isUpdateFailed = false;

    if (!this.inv.clientId || !this.selectedClient) {
      this.isUpdateFailed = true;
      return;
    }

    const invAdd = {
      manufacturer: this.inv.manufacturer,
      nomenclature: this.inv.nomenclature,
      model: this.inv.model,
      qrserialNo: this.inv.qrserialNo,
      controlNo: this.inv.controlNo,
      manualManufacturer: this.manualManufacturer,
      manualNomenclature: this.manualNomenclature,
      manualModel: this.manualModel,
      clientId: this.inv.clientId,
    };

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/" + this.item.inventoryId,
      data: invAdd,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      this.$bvModal.hide('ModalEditItem-' + this.item.ID);
      this.$emit('fetchInventoryList');
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = response.data.msg;
    }
  }

}
