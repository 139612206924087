













import AccountEditField from "../components/AccountEditField.vue";
import AccountRole from "../components/AccountRole.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { AccountEditField, AccountRole },
})
export default class ClientAccountEdit extends Vue {
  mounted() {
    this.$store.dispatch('authentication/verifyUser');
  }
}
