






























import { Component, Vue } from "vue-property-decorator";
@Component
export default class Verification extends Vue {
  code = "";
}
