































import ClientProfileTab from "../components/ClientProfileTab.vue";
import OrganizationProfileTab from "../components/OrganizationProfileTab.vue";
import OrganizationUsersTab from "../components/OrganizationUsersTab.vue";

import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ClientProfileTab,
    OrganizationProfileTab,
    OrganizationUsersTab,
  },
})
export default class ClientOrganization extends Vue {
  enablePersonnelTab: any = null;
  enableClientTab: any = null;
  isClient: any = null;
  enableClientPersonnelTab: any = null;
  hasOrganization: any = null;
  isOwner: any = null;

  mounted() {
    this.resetStates();
  }

  public resetStates () {
    this.$store.dispatch('authentication/verifyUser');
    setTimeout(() => {
      this.hasOrganization = this.$store.state.authentication.user.organizationId;

      this.isOwner = this.$store.state.authentication.user.role === 'organization owner';
      this.isClient = this.$store.state.authentication.user.role === 'organization client';

      this.enablePersonnelTab = (
        this.isOwner ||
        (
          this.$store.state.authentication.user.role === 'organization personnel' &&
          (this.$store.state.authentication.user.roles.includes('manager') || this.$store.state.authentication.user.roles.includes('clerk'))
        )
      );
      this.enableClientTab = (
        this.isOwner ||
        (
          this.$store.state.authentication.user.role === 'organization personnel' &&
          (this.$store.state.authentication.user.roles.includes('manager') || this.$store.state.authentication.user.roles.includes('clerk'))
        )
      )
      this.enableClientPersonnelTab = (
        this.isClient ||
        (
          this.$store.state.authentication.user.role === 'organization client personnel' &&
          (this.$store.state.authentication.user.roles.includes('manager') || this.$store.state.authentication.user.roles.includes('clerk'))
        )
      );
    }, 1000);
  }
}
