

















































import { Component, Vue } from "vue-property-decorator";
@Component
export default class InventoryCannotDeleteItem extends Vue {
  item = "[ITEM_IDENTIFIER]";
}
