


















































import OrganizationUsersSearchFilter from "./OrganizationUsersSearchFilter.vue";
import OrganizationUsersItem from "./OrganizationUsersItem.vue";
import OrganizationUsersEditItem from "./OrganizationUsersEditItem.vue";
import OrganizationUsersDeleteItem from "./OrganizationUsersDeleteItem.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import config from "../config";

@Component({
  components: {
    OrganizationUsersSearchFilter,
    OrganizationUsersItem,
    OrganizationUsersEditItem,
    OrganizationUsersDeleteItem,
  },
})
export default class ClientOrganizationUsersList extends Vue {
  @Prop({required: true}) userRole!: string;
  @Prop({required: true}) viewOnly!: boolean;

  fields: any = [
    { key: 'EmailAddress', sortable: false },
    { key: 'FirstName', sortable: false },
    { key: 'LastName', sortable: false },
    { key: 'Roles', sortable: false },
  ];

  list: Array<{
    ID: number;
    EmailAddress: string;
    FirstName: string;
    LastName: string;
    Roles: string;
    PhoneNumber: string;
    PhoneNumberMetadata: string;
    Client: string;
    IsRemoved: boolean;
  }> = [];

  rows = this.list.length;

  filters: any = {
    count: 0,
    page: 1,
    limit: 10,
    skip: 0,
    search_keyword: undefined,
    where: {
      role: this.userRole,
    },
  };

  user = {};

  mounted() {
    this.fetchOrganizationUsersList();
    if (!this.viewOnly) this.fields.push('Actions');
    if (this.userRole === 'organization client') {
      const client: any = [{ key: 'Client.clientName', label: 'Client', sortable: false }];
      this.fields = client.concat(this.fields);
    }

    setTimeout(() => {
      this.user = this.$store.state.authentication.user;
    }, 1000);
  }

  public async fetchOrganizationUsersList(): Promise<void> {
    this.filters.skip = (this.filters.page - 1) * this.filters.limit;

    this.filters.skip = 0;
    this.filters.limit = 0;

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "OrganizationUsers/search",
      data: this.filters,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    this.filters.count = response.data.data.organizationUsers_count;

    const OrganizationUsers = response.data.data.organizationUsers;

    const _OrganizationUsers: any = [];

    OrganizationUsers.forEach((item: any) => {
      item.roles = item.roles.filter(item => {
        return (
          item === 'technician' ||
          item === 'manager' ||
          item === 'clerk'
        );
      });
      item.roles = item.roles.join(', ');

      _OrganizationUsers.push({
        ID: item.userId,
        EmailAddress: item.email,
        FirstName: item.firstName,
        LastName: item.lastName,
        Roles: item.roles,
        PhoneNumber: item.phoneNumber,
        PhoneNumberMetadata: item.phoneNumberMetadata,
        Client: item.client,
        IsRemoved: item.isRemoved,
      });
    });

    this.list = _OrganizationUsers;
  }
}
