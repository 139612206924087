


















import ForgotPassword from "../components/ForgotPassword.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { ForgotPassword },
})
export default class Forgot extends Vue {}
