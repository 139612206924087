



















































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Qrpage from "../components/Qrpage.vue";
import axios from "axios";
import config from "../config";
import vSelect from "vue-select";

const timeout = (ms): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

@Component({
  components: {
    Qrpage,
    vSelect,
  },
})
export default class WorkRequestNew extends Vue {
  @Prop({ default: null }) manufacturers!: any;
  @Prop({ default: null }) nomenclatures!: any;
  @Prop({ default: null }) models!: any;
  @Prop({ default: null }) serialNumbers!: any;
  @Prop({ default: null }) controlNumbers!: any;
  @Prop({ default: null }) inventory!: any;

  @Prop({ default: null }) clients!: any;
  @Prop({ default: null }) technicians!: any;

  decodedString!: string;

  workRequest: {
    manufacturer: string;
    nomenclature: string;
    model: string;
    qrserialNo: string;
    controlNo: string;
    hasAllPassed: boolean;
    hasPowerUpTest: boolean;
    hasPressure: boolean;
    hasUnitMount: boolean;
    hasTightness: boolean;
    hasInternalHose: boolean;
    hasPowerCord: boolean;
    hasGroundResistance: boolean;
    hasLeakageCurrent: boolean;
    verified: boolean;
    groundResistance: string;
    pressureDisplay: string;
    symptoms: string;
    technician: string;
    scheduledForDate: string;
    scheduledForTime: string;
    type: string;
    groundResistanceNotes: string;
    cleanUnitNotes: string;
    leakageCurrentNotes: string;
    hasCleanUnit: boolean;
    leakageCurrentOff: string,
    leakageCurrentOn: string,
    clientId: string,
    hasAttentionLamp: boolean,
    hasPusherBlockMsg: boolean,
    hasCorrectSyringe: boolean,
    hasOcclusion: boolean,
    has5mlAlarm: boolean,
    hasEmptySyringe: boolean,
    hasFlowRate: boolean,
    hasGroundingPadCircuit: boolean,
    hasTestOutputs: boolean,
    testOutputs: any,
    hasPittingPaddles: boolean,
    hasUnitNotDischarge: boolean,
    hasPrinter: boolean,
    hasCheckOutputs: boolean,
    checkOutputSetting5: string,
    checkOutputSetting50: string,
    checkOutputSetting100: string,
    checkOutputSetting200: string,
    checkOutputSettingMax: string,
  } = {
    manufacturer: "",
    nomenclature: "",
    model: "",
    qrserialNo: "",
    controlNo: "",
    hasAllPassed: false,
    hasPowerUpTest: false,
    hasPressure: false,
    hasUnitMount: false,
    hasTightness: false,
    hasInternalHose: false,
    hasPowerCord: false,
    hasGroundResistance: false,
    hasLeakageCurrent: false,
    verified: false,
    groundResistance: "",
    pressureDisplay: "",
    symptoms: "",
    technician: "",
    scheduledForDate: "",
    scheduledForTime: "",
    type: "",
    groundResistanceNotes: "",
    cleanUnitNotes: "",
    leakageCurrentNotes: "",
    hasCleanUnit: false,
    leakageCurrentOff: "",
    leakageCurrentOn: "",
    clientId: "",
    hasAttentionLamp: false,
    hasPusherBlockMsg: false,
    hasCorrectSyringe: false,
    hasOcclusion: false,
    has5mlAlarm: false,
    hasEmptySyringe: false,
    hasFlowRate: false,
    hasGroundingPadCircuit: false,
    hasTestOutputs: false,
    testOutputs: [],
    hasPittingPaddles: false,
    hasUnitNotDischarge: false,
    hasPrinter: false,
    hasCheckOutputs: false,
    checkOutputSetting5: "",
    checkOutputSetting50: "",
    checkOutputSetting100: "",
    checkOutputSetting200: "",
    checkOutputSettingMax: "",
  };

  controlOrSerial = "control";

  $isMobile!:any;
  isQrEnabled = this.$isMobile();
  isCreateFailed = false;
  errorMessage = false;
  invalidAmps: any;
  invalidOhms: any;
  manualControl = false;
  manualQrserialNo = false;
  manualManufacturer = false;
  manualNomenclature = false;
  manualModel = false;
  selectedClient: any = null;

  testOutputs: any[] = [];

  templates = [
    {
      name: 'default',
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
      hasCleanUnit: true,
    },
    {
      name: 'baxterPCA2pump',
      manufacturer: 'Baxter',
      nomenclature: 'Syringe Pump',
      model: 'PCA II',
      hasPowerUpTestWithSyringe: true,
      hasAttentionLamp: true,
      hasPusherBlockMsg: true,
      hasCorrectSyringe: true,
      hasOcclusion: true,
      has5mlAlarm: true,
      hasEmptySyringe: true,
      hasFlowRate: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
    {
      name: 'generic Defib',
      hasPowerUpTest: true,
      hasUnitNotDischarge: true,
      hasPrinter: true,
      hasCheckOutputs: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
    {
      name: 'generic ESU',
      manufacturer: 'Valleylab',
      nomenclature: 'ESU',
      model: 'Force FX-2P1',
      hasPowerUpTest: true,
      hasGroundingPadCircuit: true,
      hasTestOutputs: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
    {
      name: 'strykersmartpump',
      manufacturer: 'Stryker',
      nomenclature: 'Tourniquet',
      model: 'SmartPump',
      hasPowerUpTest: true,
      hasPressure: true,
      hasUnitMount: true,
      hasTightness: true,
      hasPowerCord: true,
      hasGroundResistance: true,
      hasLeakageCurrent: true,
    },
  ];
  selectedTemplate = this.templates[0];

  @Watch('selectedTemplate')
  async OnSelectedTempate(val) {
    if (val.manufacturer) {
      if (this.manufacturers.includes(val.manufacturer)) {
        this.workRequest.manufacturer = val.manufacturer;
        await timeout(100);
      }
      else {
        this.manualManufacturer = true;
        this.workRequest.manufacturer = val.manufacturer;
      }
    }
    if (val.nomenclature) {
      if (this.nomenclatures.includes(val.nomenclature)) {
        this.workRequest.nomenclature = val.nomenclature;
        await timeout(100);
      }
      else {
        this.manualNomenclature = true;
        this.workRequest.nomenclature = val.nomenclature;
      }
    }
    if (val.model) {
      if (this.models.includes(val.model)) {
        this.workRequest.model = val.model;
      }
      else {
        this.manualModel = true;
        this.workRequest.model = val.model;
      }
    }
  }

  @Watch('workRequest.clientId')
  onClient(clientId) {
    for (const i in this.clients) {
      const client = this.clients[i];
      if (client.clientId === clientId) {
        this.selectedClient = client;
        break;
      }
    }
  }

  @Watch('controlOrSerial')
  async onControlOrSerialChange(val) {
    this.workRequest.controlNo = "";
    this.workRequest.qrserialNo = "";
    this.workRequest.manufacturer = "";
    this.workRequest.nomenclature = "";
    this.workRequest.model = "";
  }

  @Watch('workRequest.controlNo')
  async onControlChange(val) {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: {
        where: {
          controlNo: val,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      const data = response.data.data;

      if (data.inventory[0]) {
        const inventory = data.inventory[0];

        this.workRequest.qrserialNo = inventory.qrserialNo;
        this.workRequest.manufacturer = inventory.manufacturer;

        this.inventoryList.forEach((item: any) => {
          if (inventory.manufacturer !== item.manufacturer) return;

          if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
        });

        this.inventoryList.forEach((item: any) => {
          if (inventory.nomenclature !== item.nomenclature) return;

          if (!this.models.includes(item.model)) this.models.push(item.model);
        });

        setTimeout(() => {
          this.workRequest.nomenclature = inventory.nomenclature;
          this.workRequest.model = inventory.model;
        }, 100);
      }
    }
  }

  @Watch('workRequest.qrserialNo')
  async onSerialChange(val) {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: {
        where: {
          qrserialNo: val,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const success = response.data.success;

    if (success) {
      const data = response.data.data;

      if (data.inventory[0]) {
        const inventory = data.inventory[0];

        this.workRequest.controlNo = inventory.controlNo;
        this.workRequest.manufacturer = inventory.manufacturer;

        this.inventoryList.forEach((item: any) => {
          if (inventory.manufacturer !== item.manufacturer) return;

          if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
        });

        this.inventoryList.forEach((item: any) => {
          if (inventory.nomenclature !== item.nomenclature) return;

          if (!this.models.includes(item.model)) this.models.push(item.model);
        });

        setTimeout(() => {
          this.workRequest.nomenclature = inventory.nomenclature;
          this.workRequest.model = inventory.model;
        }, 100);
      }
    }
  }

  @Watch('workRequest.groundResistance')
  onGroundResistanceChange(val) {
    if (+val > 1000000) {
      this.invalidOhms = true;
    }
    else {
      this.invalidOhms = false;
    }
  }

  @Watch('workRequest.leakageCurrentOff')
  onLeakageCurrentOffChange(val) {
    if (+val > 1000000) {
      this.invalidAmps = true;
    }
    else {
      this.invalidAmps = false;
    }
  }

  @Watch('workRequest.leakageCurrentOn')
  onLeakageCurrentOnChange(val) {
    if (+val > 1000000) {
      this.invalidAmps = true;
    }
    else {
      this.invalidAmps = false;
    }
  }

  public onDecode(decodedString: string): void {
    // console.log(decodedString);
    this.controlOrSerial = 'serial';
    setTimeout(() => {
      this.workRequest.qrserialNo = decodedString;
    }, 100);
  }

  @Prop({ default: null }) inventoryList!: any;

  @Watch('workRequest.manufacturer')
  onInventoryManufacturer(manufacturer) {
    this.nomenclatures = [];
    if (!this.manualNomenclature) this.workRequest.nomenclature = '';
    if (!this.manualModel) this.workRequest.model = '';

    this.inventoryList.forEach((item: any) => {
      if (manufacturer !== item.manufacturer) return;

      if (!this.nomenclatures.includes(item.nomenclature)) this.nomenclatures.push(item.nomenclature);
    });
  }

  @Watch('workRequest.nomenclature')
  onInventoryModel(nomenclature) {
    this.models = [];
    if (!this.manualModel) this.workRequest.model = '';

    this.inventoryList.forEach((item: any) => {
      if (nomenclature !== item.nomenclature) return;

      if (!this.models.includes(item.model)) this.models.push(item.model);
    });
  }

  public async saveAndCreateNew(): Promise<void> {
    if (!this.workRequest.clientId || !this.selectedClient || this.invalidOhms || this.invalidAmps) {
      this.isCreateFailed=true;
      return;
    }
    this.isCreateFailed = false;
    const workRequestAdd = {
      manualControl: this.manualControl,
      manualQrserialNo: this.manualQrserialNo,
      manualManufacturer: this.manualManufacturer,
      manualNomenclature: this.manualNomenclature,
      manualModel: this.manualModel,
      manufacturer: this.workRequest.manufacturer,
      nomenclature: this.workRequest.nomenclature,
      model: this.workRequest.model,
      qrserialNo: this.workRequest.qrserialNo,
      controlNo: this.workRequest.controlNo,
      hasAllPassed: this.workRequest.hasAllPassed,
      hasPowerUpTest: this.workRequest.hasPowerUpTest,
      hasPressure: this.workRequest.hasPressure,
      hasUnitMount: this.workRequest.hasUnitMount,
      hasTightness: this.workRequest.hasTightness,
      hasInternalHose: this.workRequest.hasInternalHose,
      hasPowerCord: this.workRequest.hasPowerCord,
      hasGroundResistance: this.workRequest.hasGroundResistance,
      hasLeakageCurrent: this.workRequest.hasLeakageCurrent,
      verified: this.workRequest.verified,
      groundResistance: this.workRequest.groundResistance,
      pressureDisplay: this.workRequest.pressureDisplay,
      symptoms: this.workRequest.symptoms,
      technician: this.workRequest.technician,
      scheduledForDate: this.workRequest.scheduledForDate,
      scheduledForTime: this.workRequest.scheduledForTime,
      type: this.workRequest.type,
      groundResistanceNotes: this.workRequest.groundResistanceNotes,
      cleanUnitNotes: this.workRequest.cleanUnitNotes,
      leakageCurrentNotes: this.workRequest.leakageCurrentNotes,
      hasCleanUnit: this.workRequest.hasCleanUnit,
      leakageCurrentOff: this.workRequest.leakageCurrentOff,
      leakageCurrentOn: this.workRequest.leakageCurrentOn,
      clientId: this.workRequest.clientId,
      template: this.selectedTemplate.name,
      hasAttentionLamp: this.workRequest.hasAttentionLamp,
      hasPusherBlockMsg: this.workRequest.hasPusherBlockMsg,
      hasCorrectSyringe: this.workRequest.hasCorrectSyringe,
      hasOcclusion: this.workRequest.hasOcclusion,
      has5mlAlarm: this.workRequest.has5mlAlarm,
      hasEmptySyringe: this.workRequest.hasEmptySyringe,
      hasFlowRate: this.workRequest.hasFlowRate,
      hasGroundingPadCircuit: this.workRequest.hasGroundingPadCircuit,
      hasTestOutputs: this.workRequest.hasTestOutputs,
      testOutputs: this.testOutputs,
      hasPittingPaddles: this.workRequest.hasPittingPaddles,
      hasUnitNotDischarge: this.workRequest.hasUnitNotDischarge,
      hasPrinter: this.workRequest.hasPrinter,
      hasCheckOutputs: this.workRequest.hasCheckOutputs,
      checkOutputSetting5: this.workRequest.checkOutputSetting5,
      checkOutputSetting50: this.workRequest.checkOutputSetting50,
      checkOutputSetting100: this.workRequest.checkOutputSetting100,
      checkOutputSetting200: this.workRequest.checkOutputSetting200,
      checkOutputSettingMax: this.workRequest.checkOutputSettingMax,
    };
    // console.log(workRequestAdd);
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "WorkRequests/create",
      data: workRequestAdd,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });
    const success = response.data.success;

    if (success) {
      this.workRequest = {
        manufacturer: "",
        nomenclature: "",
        model: "",
        qrserialNo: "",
        controlNo: "",
        hasAllPassed: false,
        hasPowerUpTest: false,
        hasPressure: false,
        hasUnitMount: false,
        hasTightness: false,
        hasInternalHose: false,
        hasPowerCord: false,
        hasGroundResistance: false,
        hasLeakageCurrent: false,
        verified: false,
        groundResistance: "",
        pressureDisplay: "",
        symptoms: "",
        technician: "",
        scheduledForDate: "",
        scheduledForTime: "",
        type: "",
        groundResistanceNotes: "",
        cleanUnitNotes: "",
        leakageCurrentNotes: "",
        hasCleanUnit: false,
        leakageCurrentOff: "",
        leakageCurrentOn: "",
        clientId: "",
        hasAttentionLamp: false,
        hasPusherBlockMsg: false,
        hasCorrectSyringe: false,
        hasOcclusion: false,
        has5mlAlarm: false,
        hasEmptySyringe: false,
        hasFlowRate: false,
        hasGroundingPadCircuit: false,
        hasTestOutputs: false,
        testOutputs: [],
        hasPittingPaddles: false,
        hasUnitNotDischarge: false,
        hasPrinter: false,
        hasCheckOutputs: false,
        checkOutputSetting5: "",
        checkOutputSetting50: "",
        checkOutputSetting100: "",
        checkOutputSetting200: "",
        checkOutputSettingMax: "",
      };
      this.selectedTemplate = this.templates[0];

      this.manualControl = false;
      this.manualQrserialNo = false;
      this.manualManufacturer = false;
      this.manualNomenclature = false;
      this.manualModel = false;

      this.testOutputs = [];

      // console.log(response.data);
      this.$bvModal.hide('ModalWorkRequestNew');

      this.$emit('fetchWorkRequests');

      setTimeout(() => {
        this.$bvModal.show('ModalWorkRequestNew');
      }, 500);
    }
    else {
      this.isCreateFailed = true;
      // this.errorMessage = response.data.msg;
    }
  }

  public async create(): Promise<void> {
    if (!this.workRequest.clientId || !this.selectedClient || this.invalidOhms || this.invalidAmps) {
      this.isCreateFailed=true;
      return;
    }
    this.isCreateFailed = false;
    const workRequestAdd = {
      manualControl: this.manualControl,
      manualQrserialNo: this.manualQrserialNo,
      manualManufacturer: this.manualManufacturer,
      manualNomenclature: this.manualNomenclature,
      manualModel: this.manualModel,
      manufacturer: this.workRequest.manufacturer,
      nomenclature: this.workRequest.nomenclature,
      model: this.workRequest.model,
      qrserialNo: this.workRequest.qrserialNo,
      controlNo: this.workRequest.controlNo,
      hasAllPassed: this.workRequest.hasAllPassed,
      hasPowerUpTest: this.workRequest.hasPowerUpTest,
      hasPressure: this.workRequest.hasPressure,
      hasUnitMount: this.workRequest.hasUnitMount,
      hasTightness: this.workRequest.hasTightness,
      hasInternalHose: this.workRequest.hasInternalHose,
      hasPowerCord: this.workRequest.hasPowerCord,
      hasGroundResistance: this.workRequest.hasGroundResistance,
      hasLeakageCurrent: this.workRequest.hasLeakageCurrent,
      verified: this.workRequest.verified,
      groundResistance: this.workRequest.groundResistance,
      pressureDisplay: this.workRequest.pressureDisplay,
      symptoms: this.workRequest.symptoms,
      technician: this.workRequest.technician,
      scheduledForDate: this.workRequest.scheduledForDate,
      scheduledForTime: this.workRequest.scheduledForTime,
      type: this.workRequest.type,
      groundResistanceNotes: this.workRequest.groundResistanceNotes,
      cleanUnitNotes: this.workRequest.cleanUnitNotes,
      leakageCurrentNotes: this.workRequest.leakageCurrentNotes,
      hasCleanUnit: this.workRequest.hasCleanUnit,
      leakageCurrentOff: this.workRequest.leakageCurrentOff,
      leakageCurrentOn: this.workRequest.leakageCurrentOn,
      clientId: this.workRequest.clientId,
      template: this.selectedTemplate.name,
      hasAttentionLamp: this.workRequest.hasAttentionLamp,
      hasPusherBlockMsg: this.workRequest.hasPusherBlockMsg,
      hasCorrectSyringe: this.workRequest.hasCorrectSyringe,
      hasOcclusion: this.workRequest.hasOcclusion,
      has5mlAlarm: this.workRequest.has5mlAlarm,
      hasEmptySyringe: this.workRequest.hasEmptySyringe,
      hasFlowRate: this.workRequest.hasFlowRate,
      hasGroundingPadCircuit: this.workRequest.hasGroundingPadCircuit,
      hasTestOutputs: this.workRequest.hasTestOutputs,
      testOutputs: this.testOutputs,
      hasPittingPaddles: this.workRequest.hasPittingPaddles,
      hasUnitNotDischarge: this.workRequest.hasUnitNotDischarge,
      hasPrinter: this.workRequest.hasPrinter,
      hasCheckOutputs: this.workRequest.hasCheckOutputs,
      checkOutputSetting5: this.workRequest.checkOutputSetting5,
      checkOutputSetting50: this.workRequest.checkOutputSetting50,
      checkOutputSetting100: this.workRequest.checkOutputSetting100,
      checkOutputSetting200: this.workRequest.checkOutputSetting200,
      checkOutputSettingMax: this.workRequest.checkOutputSettingMax,
    };
    // console.log(workRequestAdd);
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "WorkRequests/create",
      data: workRequestAdd,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });
    const success = response.data.success;

    if (success) {
      this.workRequest = {
        manufacturer: "",
        nomenclature: "",
        model: "",
        qrserialNo: "",
        controlNo: "",
        hasAllPassed: false,
        hasPowerUpTest: false,
        hasPressure: false,
        hasUnitMount: false,
        hasTightness: false,
        hasInternalHose: false,
        hasPowerCord: false,
        hasGroundResistance: false,
        hasLeakageCurrent: false,
        verified: false,
        groundResistance: "",
        pressureDisplay: "",
        symptoms: "",
        technician: "",
        scheduledForDate: "",
        scheduledForTime: "",
        type: "",
        groundResistanceNotes: "",
        cleanUnitNotes: "",
        leakageCurrentNotes: "",
        hasCleanUnit: false,
        leakageCurrentOff: "",
        leakageCurrentOn: "",
        clientId: "",
        hasAttentionLamp: false,
        hasPusherBlockMsg: false,
        hasCorrectSyringe: false,
        hasOcclusion: false,
        has5mlAlarm: false,
        hasEmptySyringe: false,
        hasFlowRate: false,
        hasGroundingPadCircuit: false,
        hasTestOutputs: false,
        testOutputs: [],
        hasPittingPaddles: false,
        hasUnitNotDischarge: false,
        hasPrinter: false,
        hasCheckOutputs: false,
        checkOutputSetting5: "",
        checkOutputSetting50: "",
        checkOutputSetting100: "",
        checkOutputSetting200: "",
        checkOutputSettingMax: "",
      };
      this.selectedTemplate = this.templates[0];

      this.manualControl = false;
      this.manualQrserialNo = false;
      this.manualManufacturer = false;
      this.manualNomenclature = false;
      this.manualModel = false;

      this.testOutputs = [];

      // console.log(response.data);
      this.$bvModal.hide('ModalWorkRequestNew');
      this.$emit('fetchWorkRequests');
    }
    else {
      this.isCreateFailed = true;
      // this.errorMessage = response.data.msg;
    }
  }

  created() {
    this.invalidAmps = false;
    this.invalidOhms = false;

    this.$root.$on('bv::modal::show', () => {
      if (this.inventory) {
        this.workRequest.controlNo = this.inventory.ControlNo;
        this.workRequest.qrserialNo = this.inventory.SerialNo;
        this.workRequest.manufacturer = this.inventory.Manufacturer;
        this.workRequest.nomenclature = this.inventory.Nomenclature;
        this.workRequest.model = this.inventory.Model;
      }

      console.log(this.clients);

      if (this.clients && this.clients.length && !this.selectedClient) {
        this.workRequest.clientId = this.clients[0].clientId;
        this.selectedClient = this.clients[0];
      }

      this.testOutputs = [];

      for (let i = 0; i < 5; i++) {
        this.testOutputs.push({
          setting: '',
          ablate: '',
          coblate: '',
        });
      }
    });
  }
}
