


















































// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BIconFileEarmarkMinusFill } from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
@Component({
  components: {},
})
export default class Login extends Vue {
  @Prop({default: ''}) email;
  @Prop({default: ''}) password;
  @Prop({default: false}) submitted;

  get loggingIn() {
    return this.$store.state.authentication.status.loggingIn;
  }

  get loggedIn() {
    return this.$store.state.authentication.status.loggedIn;
  }

  get isSubmitted() {
    return this.submitted;
  }

  handleSubmit (e) {
    this.submitted = true;

    const { dispatch } = this.$store;
    if (this.email && this.password) {
      dispatch('authentication/login', {email: this.email, password: this.password});
    }
  }
}
