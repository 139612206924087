


































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import config from "../config";

@Component
export default class OrganizationDelete extends Vue {
  @Prop({default: ""}) item !: any;
  @Prop({default: ""}) first_name !: string;
  @Prop({default: ""}) last_name !: string;

  public async remove(): Promise<void> {
    await axios({
      method: 'DELETE',
      url: config.apiEndpoint + "organizationUsers/" + this.item.ID,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });
    this.$bvModal.hide('ModalDeleteUser-' + this.item.ID);
    this.$emit('fetchOrganizationUsersList');
  }
}
