import { userService } from '../services';
import router from '../router';
import axios from "axios";
import config from "../config";

let user = localStorage.getItem('user');
if (user) user = JSON.parse(user);
const token = localStorage.getItem('token');
const initialState: any = user
  ? { status: { loggedIn: true }, user, token }
  : { status: {}, user: null, token: null };

const getUser = async (): Promise<void> => {
  try {
    const response: any = await axios({
      method: 'get',
      url: config.apiEndpoint + "users/get-user",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + token,
        'x-access-token': token
      },
    });

    if (response.status === 200) return;

    userService.logout();

    router.push('/login');
  } catch (error) {
    userService.logout();

    router.push('/login');
  }
}

if (user && token) {
  getUser();
}
else {
  setTimeout(() => {
    userService.logout();
    const rounterName = router.currentRoute.name;
    if (rounterName === 'Login' || rounterName === 'Register') return;
    router.push('/login');
  }, 1000)
}

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { email, password }) {
      commit('loginRequest', { email });

      userService.login(email, password)
        .then(
          data => {
            commit('loginSuccess', data);

            if(data.user.emailVerified) {
              router.push('/client/request/list');
            }
            else {
              router.push('confirm-email');
            }
          },
          error => {
            commit('loginFailure', error.message);
          }
        );
    },
    register({ dispatch, commit }, { email, password, firstName, lastName, phoneNumber, phoneNumberMetadata }) {
      commit('loginRequest', { email });

      userService.register(email, password, firstName, lastName, phoneNumber, phoneNumberMetadata)
        .then(
          data => {
            commit('registerSuccess', data);
            router.push('/confirm-email');
          },
          error => {
            commit('registerFailure', error.message);
          }
        );
    },
    logout({ commit }) {
      userService.logout();
      commit('logout');
    },
    verifyUser({ commit }) {
      commit('verifyUser');

      userService.verifyUser()
        .then(
          data => {
            commit('verifyUserSuccess', data);
            if (!data.user.emailVerified) {
              router.push('/confirm-email');
            }
          },
          error => {
            router.push('/login');
            commit('verifyUserFailure', error.message);
          }
        );
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, data) {
      state.status = { loggedIn: true };
      state.user = data.user;
      state.token = data.accessToken;
    },
    loginFailure(state, errorMessage) {
      state.status = { loggedIn: false, errorMessage };
      state.user = null;
    },
    registerRequest(state, user) {
      state.status = { registeringIn: true };
      state.user = user;
    },
    registerSuccess(state, data) {
      state.status = { loggedIn: true };
      state.user = data.user;
      state.token = data.accessToken;
    },
    registerFailure(state, errorMessage) {
      state.status = { errorMessage };
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    verifyUser(state) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    verifyUserSuccess(state, data) {
      state.status = { loggedIn: true };
      state.user = data.user;
    },
    verifyUserFailure(state, errorMessage) {
      state.status = { loggedIn: false };
      state.status = { errorMessage };
      state.user = null;
    }
  }
}
