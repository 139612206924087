

































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import { usStatesCitiesService } from '../services';
import config from "../config";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

@Component({
  components: {
    VuePhoneNumberInput,
  },
})
export default class OrganizationProfileTab extends Vue {
  @Prop({required: true}) viewOnly!: boolean;

  formData: {
    organizationName: string;
    contactPerson: string;
    email: string;
    addressLine: string;
    addressLine2: string;
    contactNumber: string;
    state: string;
    city: string;
    zipCode: string;
  } = {
    organizationName: "",
    contactPerson: "",
    email: "",
    addressLine: "",
    addressLine2: "",
    contactNumber: "",
    state: "",
    city: "",
    zipCode: "",
  };

  states = [];
  cities = [];
  contactNumber = '';

  error: {
    contactNumber: boolean;
  } = {
    contactNumber: false,
  };

  onChangeContactNumber = (val) => {
    this.error.contactNumber = !val.isValid;
    if (!val.isValid) return;
    this.formData.contactNumber = val;
  }

  mounted() {
    this.states = usStatesCitiesService.getAllStates();

    this.getFormData();

    this.$store.dispatch('authentication/verifyUser');
  }

  @Watch('formData.state')
  onControlChange(val: any) {
    this.cities = usStatesCitiesService.getCities(val);
    this.formData.state = val;
  }

  isNewAccountSetup: any = null;

  async getFormData() {
    const response: any = await axios({
      method: 'get',
      url: config.apiEndpoint + "organizations",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (response.data.success) {
      const organization = response.data.data.organization;

      if (organization) {
        this.contactNumber = organization.contactNumber.formattedNumber;

        this.formData.organizationName = organization.organizationName || organization.clientName;
        this.formData.contactPerson = organization.contactPerson;
        this.formData.email = organization.email;
        this.formData.addressLine = organization.address1;
        this.formData.addressLine2 = organization.address2;
        this.formData.contactNumber = organization.contactNumber;
        this.formData.state = organization.state;
        this.formData.city = organization.city;
        this.formData.zipCode = organization.zipCode;

        this.isNewAccountSetup = false;
      }
    }
    else {
      this.isNewAccountSetup = true;
    }
  }

  isUpdateFailed = false;
  isUpdateSuccess = false;
  errorMessage = false;
  saving = false;
  isSubmitted = false;

  async save(): Promise<void> {
    this.isSubmitted = true;
    this.saving = true;
    this.isUpdateFailed = false;
    this.isUpdateSuccess = false;

    if (
      this.formData.organizationName &&
      this.formData.contactPerson &&
      this.formData.email &&
      this.formData.addressLine &&
      this.formData.contactNumber &&
      this.formData.state &&
      this.formData.city &&
      this.formData.zipCode &&
      !this.error.contactNumber
    ) {
      try {
        const formData = {
          organizationName: this.formData.organizationName,
          contactPerson: this.formData.contactPerson,
          email: this.formData.email,
          address1: this.formData.addressLine,
          address2: this.formData.addressLine2,
          contactNumber: this.formData.contactNumber,
          state: this.formData.state,
          city: this.formData.city,
          zipCode: this.formData.zipCode,
        };

        const action = this.isNewAccountSetup ? "create" : "update";

        const response: any = await axios({
          method: 'post',
          url: config.apiEndpoint + "organizations/" + action,
          data: formData,
          headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + this.$store.state.authentication.token,
            'x-access-token': this.$store.state.authentication.token
          },
        });
        const success = response.data.success;

        if (success) {
          this.isUpdateSuccess = true;

          this.$store.dispatch('authentication/verifyUser');

          this.isNewAccountSetup = false;

          this.$emit('resetStates');
        }
        else {
          this.isUpdateFailed = true;
        }
      } catch (err) {
        this.isUpdateFailed = true;
      }
    }
    else {
      this.isUpdateFailed = true;
    }
    this.saving = false;
  }
}
