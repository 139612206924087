






















































import WorkRequestEdit from "../components/WorkRequestEdit.vue";
import WorkRequestNew from "../components/WorkRequestNew.vue";
import WorkRequestSearchFilter from "../components/WorkRequestSearchFilter.vue";
import WorkRequestDeleteItem from "../components/WorkRequestDeleteItem.vue";
import WorkRequestCannotDeleteItem from "../components/WorkRequestCannotDeleteItem.vue";
import WorkRequestExport from "../components/WorkRequestExport.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import config from "../config";
import moment from "moment";

@Component({
  components: {
    WorkRequestEdit,
    WorkRequestNew,
    WorkRequestSearchFilter,
    WorkRequestDeleteItem,
    WorkRequestCannotDeleteItem,
    WorkRequestExport,
  },
})
export default class ClientWorkRequestList extends Vue {
  fields: any[] = [
    { key: 'WorkNo', label: 'Workorder #', sortable: true },
    { key: 'ControlNo', sortable: true },
    { key: 'Manufacturer', sortable: true },
    { key: 'Model', sortable: true },
    { key: 'SerialNo', sortable: true },
    { key: 'Technician.fullName', label: 'Technician', sortable: true },
    { key: 'Request', sortable: true },
    { key: 'Schedule', sortable: true },
    { key: 'Status', sortable: true },
    { key: 'Actions', sortable: false },
  ];

  list: Array<{
    WorkNo: string;
    Manufacturer: string;
    Nomenclature: string;
    Model: string;
    SerialNo: string;
    ControlNo: string;
    HasAllPassed: boolean;
    HasPowerUpTest: boolean;
    HasPressure: boolean;
    HasUnitMount: boolean;
    HasTightness: boolean;
    HasInternalHose: boolean;
    HasPowerCord: boolean;
    HasGroundResistance: boolean;
    HasLeakageCurrent: boolean;
    Verified: boolean;
    GroundResistance: string;
    PressureDisplay: string;
    Symptoms: string;
    Technician: string;
    Type: string;
    HasGroundResistanceNotes: string;
    CleanUnitNotes: string;
    LeakageCurrentNotes: string;
    HasCleanUnit: string;
    LeakageCurrentOff: string;
    LeakageCurrentOn: string;
    manualControl: boolean;
    manualQrserialNo: boolean;
    manualManufacturer: boolean;
    manualNomenclature: boolean;
    manualModel: boolean;
    clientId: string;
    template: string;
    hasAttentionLamp: boolean,
    hasPusherBlockMsg: boolean,
    hasCorrectSyringe: boolean,
    hasOcclusion: boolean,
    has5mlAlarm: boolean,
    hasEmptySyringe: boolean,
    hasFlowRate: boolean,
    hasGroundingPadCircuit: boolean,
    hasTestOutputs: boolean,
    testOutputs: any,
    hasPittingPaddles: boolean,
    hasUnitNotDischarge: boolean,
    hasPrinter: boolean,
    hasCheckOutputs: boolean,
    checkOutputSetting5: string,
    checkOutputSetting50: string,
    checkOutputSetting100: string,
    checkOutputSetting200: string,
    checkOutputSettingMax: string,
  }> = [];
  rows = this.list.length;

  filters: any = {
    count: 0,
    page: 1,
    limit: 10,
    skip: 0,
    search_keyword: undefined,
    where: {
      status: undefined,
      controlNo: undefined,
      qrserialNo: undefined,
      nomenclature: undefined,
      model: undefined,
      manufacturer: undefined,
    },
    order: undefined,
  }

  created() {
    this.fetchWorkRequests();
    this.fetchSerialNumbers();
    this.fetchControlNumbers();
    this.fetchInventoryList();
    this.getClients();
    this.getTechnicians();
    setTimeout(() => {
      const url_string = window.location.href
      const url = new URL(url_string);
      const create = url.searchParams.get("create");
      if (create) this.$bvModal.show('ModalWorkRequestNew');
    }, 500);
  }

  manufacturers: any = [];

  nomenclatures: any = [];

  models: any = [];

  inventoryList = [];

  public async fetchInventoryList(): Promise<void> {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: this.filters,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const inventory = response.data.data.inventory;

    inventory.forEach((item: any) => {
      if (!this.manufacturers.includes(item.manufacturer)) this.manufacturers.push(item.manufacturer);
    });

    this.inventoryList = inventory;
  }

  serialNumbers: any[] = [];

  public async fetchSerialNumbers(): Promise<void> {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: {},
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const inventory = response.data.data.inventory;

    inventory.forEach((item: any) => {
      this.serialNumbers.push(item.qrserialNo);
    });

    // console.log(this.serialNumbers);
  }

  controlNumbers: any[] = [];

  public async fetchControlNumbers(): Promise<void> {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "inventory/search",
      data: {},
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    const inventory = response.data.data.inventory;

    inventory.forEach((item: any) => {
      this.controlNumbers.push(item.controlNo);
    });

    // console.log(this.controlNumbers);
  }

  isLoading = false;

  public async fetchWorkRequests(): Promise<void> {
    this.filters.skip = (this.filters.page - 1) * this.filters.limit;

    this.filters.skip = 0;
    this.filters.limit = 0;

    this.isLoading = true;

    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "WorkRequests/search",
      data: this.filters,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    this.isLoading = false;

    this.filters.count = response.data.data.workRequest_count;

    const WorkRequests = response.data.data.workRequests;

    const _workRequests: any = [];

    WorkRequests.forEach((item: any) => {
      const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      };
      _workRequests.push({
        workRequestId: item.workRequestId,
        ID: pad(item.numberId, 8, '0'),
        WorkNo: pad(item.numberId, 8, '0'),
        ControlNo: item.controlNo,
        Manufacturer: item.manufacturer,
        Nomenclature: item.nomenclature,
        Model: item.model,
        SerialNo: item.qrserialNo,
        HasAllPassed: item.hasAllPassed,
        HasPowerUpTest: item.hasPowerUpTest,
        HasPressure: item.hasPressure,
        HasUnitMount: item.hasUnitMount,
        HasTightness: item.hasTightness,
        HasInternalHose: item.hasInternalHose,
        HasPowerCord: item.hasPowerCord,
        HasGroundResistance: item.hasGroundResistance,
        HasLeakageCurrent: item.hasLeakageCurrent,
        Verified: item.verified,
        GroundResistance: item.groundResistance,
        PressureDisplay: item.pressureDisplay,
        Symptoms: item.symptoms,
        Technician: item.technician || {},
        ScheduledForDate: item.scheduledForDate,
        ScheduledForTime: item.scheduledForTime,
        Status: item.status,
        Request: item.type,
        Schedule: moment(item.schedule).format("MM-DD-YYYY HH:mm:ss"),
        HasGroundResistanceNotes: item.groundResistanceNotes,
        CleanUnitNotes: item.cleanUnitNotes,
        LeakageCurrentNotes: item.leakageCurrentNotes,
        HasCleanUnit: item.hasCleanUnit,
        LeakageCurrentOff: item.leakageCurrentOff,
        LeakageCurrentOn: item.leakageCurrentOn,
        manualControl: item.manualControl,
        manualQrserialNo: item.manualQrserialNo,
        manualManufacturer: item.manualManufacturer,
        manualNomenclature: item.manualNomenclature,
        manualModel: item.manualModel,
        clientId: item.clientId,
        template: item.template,
        hasAttentionLamp: item.hasAttentionLamp,
        hasPusherBlockMsg: item.hasPusherBlockMsg,
        hasCorrectSyringe: item.hasCorrectSyringe,
        hasOcclusion: item.hasOcclusion,
        has5mlAlarm: item.has5mlAlarm,
        hasEmptySyringe: item.hasEmptySyringe,
        hasFlowRate: item.hasFlowRate,
        hasGroundingPadCircuit: item.hasGroundingPadCircuit,
        hasTestOutputs: item.hasTestOutputs,
        testOutputs: item.testOutputs,
        hasPittingPaddles: item.hasPittingPaddles,
        hasUnitNotDischarge: item.hasUnitNotDischarge,
        hasPrinter: item.hasPrinter,
        hasCheckOutputs: item.hasCheckOutputs,
        checkOutputSetting5: item.checkOutputSetting5,
        checkOutputSetting50: item.checkOutputSetting50,
        checkOutputSetting100: item.checkOutputSetting100,
        checkOutputSetting200: item.checkOutputSetting200,
        checkOutputSettingMax: item.checkOutputSettingMax,
      });
    });

    this.list = _workRequests;
  }

  clients: any = null;

  async getClients() {
    const response: any = await axios({
      method: 'post',
      url: config.apiEndpoint + "clients/search",
      data: {},
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (response.data.success) {
      this.clients = response.data.data.clients;
    }
  }

  technicians = [];

  async getTechnicians() {
    const response: any = await axios({
      method: 'get',
      url: config.apiEndpoint + "users/fetchTechnicians",
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });

    if (response.data.success) {
      this.technicians = response.data.data.technicians;
    }
  }
}
