







import { Component, Vue } from "vue-property-decorator";
@Component
export default class AccountRole extends Vue {
  role = "";

  mounted() {
    this.role = this.$store.state.authentication.user.role;
  }
}
