import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ConfirmEmail from "../views/ConfirmEmail.vue";
import Verify from "../views/Verify.vue";
import Register from "../views/Register.vue";
import Forgot from "../views/Forgot.vue";
import Reset from "../views/Reset.vue";
import ClientAccountEdit from "../views/ClientAccountEdit.vue";
import ClientInventoryList from "../views/ClientInventoryList.vue";
import ClientOrganization from "../views/ClientOrganization.vue";
import ClientWorkRequestList from "../views/ClientWorkRequestList.vue";
import ClientWorkRequestEdit from "../views/ClientWorkRequestEdit.vue";

import Limbo from "../views/Limbo.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: ConfirmEmail,
  },
  {
    path: "/verify",
    name: "Verify",
    component: Verify,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: Forgot,
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
  },
  {
    path: "/client/account/edit",
    name: "ClientAccountEdit",
    component: ClientAccountEdit,
  },
  {
    path: "/client/inventory/list",
    name: "ClientInventoryList",
    component: ClientInventoryList,
  },
  {
    path: "/client/organization",
    name: "ClientOrganization",
    component: ClientOrganization,
  },
  {
    path: "/client/request/list",
    name: "ClientRequestList",
    component: ClientWorkRequestList,
  },
  {
    path: "/limbo",
    name: "Limbo",
    component: Limbo,
  },
  {
    path: "/client/request/edit",
    name: "ClientWorkRequestEdit",
    component: ClientWorkRequestEdit,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
