


































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import config from "../config";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { usStatesCitiesService } from '../services';
import Multiselect from 'vue-multiselect'

@Component({
  components: {
    VuePhoneNumberInput,
    Multiselect,
  },
})
export default class OrganizationUsersEditItem extends Vue {
  @Prop({default: null}) item !: any;
  @Prop({default: null}) email!: any;
  @Prop({default: null}) first_name!: any;
  @Prop({default: null}) last_name!: any;
  @Prop({default: null}) roles !: string;
  @Prop({default: null}) phone_number !: string;
  @Prop({default: ""}) phone_number_metadata !: string;
  @Prop({default: null}) client !: any;

  @Prop({required: true}) userRole!: string;

  formData: {
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    phoneNumber: string;
    phoneNumberMetadata: string;
    password: string;
    userId: string;
  } = {
    email: this.email,
    firstName: this.first_name,
    lastName: this.last_name,
    roles: this.roles ? this.roles.split(', ') : [],
    phoneNumber: this.phone_number,
    phoneNumberMetadata: this.phone_number_metadata,
    password: "",
    userId: this.item.ID,
  };

  clientFormData: {
    clientName: string;
    contactPerson: string;
    email: string;
    addressLine: string;
    addressLine2: string;
    contactNumber: string;
    state: string;
    city: string;
    zipCode: string;
    userId: string,
  } = {
    clientName: this.client ? this.client.clientName: "",
    contactPerson: this.client ? this.client.contactPerson: "",
    email: this.client ? this.client.email: "",
    addressLine: this.client ? this.client.address1: "",
    addressLine2: this.client ? this.client.address2: "",
    contactNumber: this.client ? this.client.contactNumber: "",
    state: this.client ? this.client.state: "",
    city: this.client ? this.client.city: "",
    zipCode: this.client ? this.client.zipCode: "",
    userId: this.item.ID,
  };

  states = [];
  cities = [];
  contactNumber = '';

  inputPhoneNumber = "";

  error: {
    phoneNumber: boolean;
    contactNumber: boolean;
  } = {
    phoneNumber: false,
    contactNumber: false,
  };

  created() {
    this.$root.$on('bv::modal::show', () => {
      this.inputPhoneNumber = this.phone_number;

      if (this.client) {
        this.cities = usStatesCitiesService.getCities(this.client.state);
      }

      if (this.client && this.client.contactNumber) {
        this.contactNumber = this.client.contactNumber.formatInternational;
      }

      console.log(this.formData.roles);
    });
  }

  onChangePhoneNumber = (val) => {
    this.error.phoneNumber = !val.isValid;
    if (!val.isValid) return;
    this.formData.phoneNumberMetadata = val;
    this.formData.phoneNumber = val.formatInternational;
  }

  onChangeContactNumber = (val) => {
    this.error.contactNumber = !val.isValid;
    if (!val.isValid) return;
    this.clientFormData.contactNumber = val;
  }

  roleOptions: string[] = [];
  method = '';

  phoneNumber = '';

  mounted() {
    this.states = usStatesCitiesService.getAllStates();

    this.roleOptions = ['technician', 'manager', 'clerk'];
  }

  @Watch('clientFormData.state')
  onControlChange(val: any) {
    this.cities = usStatesCitiesService.getCities(val);
    this.clientFormData.state = val;
  }

  isUpdateFailed = false;
  errorMessage: any = false;
  isSubmitted = false;

  public async updateUser(): Promise<void> {
    if (this.userRole === 'personnel') {
      await this.savePersonnel();
    }
    else {
      await this.saveClient();
    }
  }

  public async savePersonnel(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    if (
      this.formData.email &&
      this.formData.firstName &&
      this.formData.lastName &&
      (this.formData.roles && this.formData.roles.length) &&
      this.formData.phoneNumber &&
      !this.error.phoneNumber
    ) {
      const isClient = this.$store.state.authentication.user.role === 'organization client';
      const method = isClient ? 'updateOrganizationClientPersonnel' : 'updateOrganizationPersonnel';

      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + `organizationUsers/${method}`,
        data: this.formData,
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.$bvModal.hide('ModalEditUser-' + this.item.ID);
        this.$emit('fetchOrganizationUsersList');
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }

  public async saveClient(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    if (
      this.formData.email &&
      this.formData.firstName &&
      this.formData.lastName &&
      this.formData.phoneNumber &&
      !this.error.phoneNumber &&
      this.clientFormData.clientName &&
      this.clientFormData.contactPerson &&
      this.clientFormData.email &&
      this.clientFormData.addressLine &&
      this.clientFormData.contactNumber &&
      this.clientFormData.state &&
      this.clientFormData.city &&
      this.clientFormData.zipCode &&
      !this.error.contactNumber
    ) {
      const clientFormData = {
        clientName: this.clientFormData.clientName,
        contactPerson: this.clientFormData.contactPerson,
        email: this.clientFormData.email,
        address1: this.clientFormData.addressLine,
        address2: this.clientFormData.addressLine2,
        contactNumber: this.clientFormData.contactNumber,
        state: this.clientFormData.state,
        city: this.clientFormData.city,
        zipCode: this.clientFormData.zipCode,
      };

      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + "organizationUsers/updateOrganizationClient",
        data: {
          user: this.formData,
          client: clientFormData,
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.$bvModal.hide('ModalEditUser-' + this.item.ID);
        this.$emit('fetchOrganizationUsersList');
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }
}
