
































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import config from "../config";

@Component
export default class InventoryDeleteItem extends Vue {
  @Prop({ default: "" }) item!: any;
  @Prop({ default: null }) list!: any;

  public async remove(): Promise<void> {
    await axios({
      method: 'delete',
      url: config.apiEndpoint + `inventory/${this.item.inventoryId}`,
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + this.$store.state.authentication.token,
        'x-access-token': this.$store.state.authentication.token
      },
    });
    this.$bvModal.hide('ModalDeleteItem-' + this.item.ID);
    this.$emit('fetchInventoryList');
  }
}
