













import Registration from "../components/Registration.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { Registration },
})
export default class Register extends Vue {}
