







import Navbar from "./components/Navbar.vue";
import { Component, Vue } from "vue-property-decorator";
import "vue-select/dist/vue-select.css";

let fontawesomeScript = document.createElement('script')
fontawesomeScript.setAttribute('src', 'https://kit.fontawesome.com/b0ce682990.js')
document.head.appendChild(fontawesomeScript)

let bootstrapCSSScript = document.createElement('script')
bootstrapCSSScript.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css')
document.head.appendChild(bootstrapCSSScript)

let jqueryScript = document.createElement('script')
jqueryScript.setAttribute('src', 'https://code.jquery.com/jquery-3.4.1.slim.min.js')
document.head.appendChild(jqueryScript)

let bootstrapJSScript = document.createElement('script')
bootstrapJSScript.setAttribute('src', 'https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js')
document.head.appendChild(bootstrapJSScript)

@Component({ components: { Navbar } })
export default class App extends Vue {}
