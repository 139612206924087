













import WorkRequestNew from "../components/WorkRequestNew.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    WorkRequestNew,
  },
})
export default class ClientWorkRequestEdit extends Vue {}
