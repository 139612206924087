import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/fontawesome-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueQrcodeReader from "vue-qrcode-reader";
import StreamBarcodeReader from "vue-barcode-scanner";
import VueMobileDetection from "vue-mobile-detection";
import Moment from "vue-moment";

//import { faBarcode } from "@fortawesome/free-solid-svg-icons";

Vue.config.productionTip = false;
Vue.use(VueQrcodeReader);
Vue.use(StreamBarcodeReader);
Vue.use(VueMobileDetection);
Vue.use(Moment);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
