













































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import config from "../config";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { usStatesCitiesService } from '../services';
import Multiselect from 'vue-multiselect'

@Component({
  components: {
    VuePhoneNumberInput,
    Multiselect,
  },
})
export default class OrganizationUsersItem extends Vue {
  @Prop({required: true}) userRole!: string;

  formData: {
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    phoneNumber: string;
    phoneNumberMetadata: string;
    password: string;
  } = {
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
    phoneNumber: "",
    phoneNumberMetadata: "",
    password: "",
  };

  clientFormData: {
    clientName: string;
    contactPerson: string;
    email: string;
    addressLine: string;
    addressLine2: string;
    contactNumber: string;
    state: string;
    city: string;
    zipCode: string;
  } = {
    clientName: "",
    contactPerson: "",
    email: "",
    addressLine: "",
    addressLine2: "",
    contactNumber: "",
    state: "",
    city: "",
    zipCode: "",
  };

  states = [];
  cities = [];
  contactNumber = '';

  inputPhoneNumber = "";

  error: {
    phoneNumber: boolean;
    contactNumber: boolean;
  } = {
    phoneNumber: false,
    contactNumber: false,
  };

  onChangePhoneNumber = (val) => {
    this.error.phoneNumber = !val.isValid;
    if (!val.isValid) return;
    this.formData.phoneNumberMetadata = val;
    this.formData.phoneNumber = val.formatInternational;
  }

  onChangeContactNumber = (val) => {
    this.error.contactNumber = !val.isValid;
    if (!val.isValid) return;
    this.clientFormData.contactNumber = val;
  }

  roleOptions: any[] = [];
  method = '';

  phoneNumber = '';

  mounted() {
    this.states = usStatesCitiesService.getAllStates();

    this.roleOptions = ['technician', 'manager', 'clerk'];
  }

  @Watch('clientFormData.state')
  onControlChange(val: any) {
    this.cities = usStatesCitiesService.getCities(val);
    this.clientFormData.state = val;
  }

  isUpdateFailed = false;
  errorMessage: any = false;
  isSubmitted = false;

  public async createUser(): Promise<void> {
    if (this.userRole === 'personnel') {
      await this.savePersonnel();
    }
    else {
      await this.saveClient();
    }
  }

  public async createUserAndCreateNew(): Promise<void> {
    if (this.userRole === 'personnel') {
      await this.savePersonnelAndCreateNew();
    }
    else {
      await this.saveClientAndCreateNew();
    }
  }

  public async savePersonnel(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    if (
      this.formData.email &&
      this.formData.firstName &&
      this.formData.lastName &&
      (this.formData.roles && this.formData.roles.length) &&
      this.formData.phoneNumber &&
      this.formData.password &&
      !this.error.phoneNumber
    ) {
      const isClient = this.$store.state.authentication.user.role === 'organization client';
      const method = isClient ? 'createOrganizationClientPersonnel' : 'createOrganizationPersonnel';
      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + `organizationUsers/${method}`,
        data: this.formData,
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.$bvModal.hide(`ModalAdd${this.userRole}`);
        this.$emit('fetchOrganizationUsersList');
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }

  public async savePersonnelAndCreateNew(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    if (
      this.formData.email &&
      this.formData.firstName &&
      this.formData.lastName &&
      (this.formData.roles && this.formData.roles.length) &&
      this.formData.phoneNumber &&
      this.formData.password &&
      !this.error.phoneNumber
    ) {
      const isClient = this.$store.state.authentication.user.role === 'organization client';
      const method = isClient ? 'createOrganizationClientPersonnel' : 'createOrganizationPersonnel';
      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + `organizationUsers/${method}`,
        data: this.formData,
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.formData = {
          email: "",
          firstName: "",
          lastName: "",
          roles: [],
          phoneNumber: "",
          phoneNumberMetadata: "",
          password: "",
        };

        this.$bvModal.hide('ModalAdd');
        this.$emit('fetchOrganizationUsersList');

        setTimeout(() => {
          this.isSubmitted = false;
          this.$bvModal.show('ModalAdd');
        }, 500);
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }

  public async saveClient(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    if (
      this.formData.email &&
      this.formData.firstName &&
      this.formData.lastName &&
      this.formData.phoneNumber &&
      this.formData.password &&
      !this.error.phoneNumber &&
      this.clientFormData.clientName &&
      this.clientFormData.contactPerson &&
      this.clientFormData.email &&
      this.clientFormData.addressLine &&
      this.clientFormData.contactNumber &&
      this.clientFormData.state &&
      this.clientFormData.city &&
      this.clientFormData.zipCode &&
      !this.error.contactNumber
    ) {
      const clientFormData = {
        clientName: this.clientFormData.clientName,
        contactPerson: this.clientFormData.contactPerson,
        email: this.clientFormData.email,
        address1: this.clientFormData.addressLine,
        address2: this.clientFormData.addressLine2,
        contactNumber: this.clientFormData.contactNumber,
        state: this.clientFormData.state,
        city: this.clientFormData.city,
        zipCode: this.clientFormData.zipCode,
      };

      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + "organizationUsers/createOrganizationClient",
        data: {
          user: this.formData,
          client: clientFormData,
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.$bvModal.hide(`ModalAdd${this.userRole}`);
        this.$emit('fetchOrganizationUsersList');
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }

  public async saveClientAndCreateNew(): Promise<void> {
    this.isUpdateFailed = false;
    this.isSubmitted = true;
    this.errorMessage = false;
    if (
      this.formData.email &&
      this.formData.firstName &&
      this.formData.lastName &&
      this.formData.phoneNumber &&
      this.formData.password &&
      !this.error.phoneNumber &&
      this.clientFormData.clientName &&
      this.clientFormData.contactPerson &&
      this.clientFormData.email &&
      this.clientFormData.addressLine &&
      this.clientFormData.contactNumber &&
      this.clientFormData.state &&
      this.clientFormData.city &&
      this.clientFormData.zipCode &&
      !this.error.contactNumber
    ) {
      const clientFormData = {
        clientName: this.clientFormData.clientName,
        contactPerson: this.clientFormData.contactPerson,
        email: this.clientFormData.email,
        address1: this.clientFormData.addressLine,
        address2: this.clientFormData.addressLine2,
        contactNumber: this.clientFormData.contactNumber,
        state: this.clientFormData.state,
        city: this.clientFormData.city,
        zipCode: this.clientFormData.zipCode,
      };

      const response: any = await axios({
        method: 'post',
        url: config.apiEndpoint + "organizationUsers/createOrganizationClient",
        data: {
          user: this.formData,
          client: clientFormData,
        },
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + this.$store.state.authentication.token,
          'x-access-token': this.$store.state.authentication.token
        },
      });
      const success = response.data.success;

      if (success) {
        this.formData = {
          email: "",
          firstName: "",
          lastName: "",
          roles: [],
          phoneNumber: "",
          phoneNumberMetadata: "",
          password: "",
        };

        this.inputPhoneNumber = "";

        this.clientFormData = {
          clientName: "",
          contactPerson: "",
          email: "",
          addressLine: "",
          addressLine2: "",
          contactNumber: "",
          state: "",
          city: "",
          zipCode: "",
        },

        this.contactNumber = "";

        this.$bvModal.hide('ModalAdd');
        this.$emit('fetchOrganizationUsersList');

        setTimeout(() => {
          this.isSubmitted = false;
          this.$bvModal.show('ModalAdd');
        }, 500);
      }
      else {
        this.isUpdateFailed = true;
        this.errorMessage = response.data.msg;
      }
    }
    else {
      this.isUpdateFailed = true;
      this.errorMessage = "Save Failed! Please update fields.";
    }
  }
}
